import React, { useState } from 'react';
import Pagination from 'shared/components/andtComponents/Pagination';
import { Search } from '../../../components/Search';
import { DEFAULT_PAGE_SIZE } from '../../../consts';

import styles from './AddSubRolesModal.module.scss';

const AddSubRolesModalHeader = ({ selectedRolesCount, searchText, setSearchText, total, pageChanged }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = total >= 0 ? Math.ceil(total / DEFAULT_PAGE_SIZE) : 0;

  const onPageChange = (newPage) => {
    pageChanged(newPage - currentPage);
    setCurrentPage(newPage);
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        <div className={styles.header}>
          <div className={styles.title}>
            Roles <span className={styles.count}>{selectedRolesCount}</span>
          </div>
        </div>
      </div>
      <div className={styles.headerRight}>
        <Search searchText={searchText} setSearchText={setSearchText} />
        <Pagination currPageIdx={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
      </div>
    </div>
  );
};

export default AddSubRolesModalHeader;
