import React, { useEffect, useState } from 'react';
import { ICONS, GenerateIcon, SelectMultiItem } from '@pileus-cloud/anodot-frontend-common/dist';
import Input from 'shared/components/andtComponents/Input';
import Button from 'shared/components/andtComponents/Button';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import PropTypes from 'prop-types';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { withStyles } from '@mui/styles';
import { components } from 'react-select';
import Pagination from 'shared/components/andtComponents/Pagination';
import classes from './recommendationsTableHeader.module.scss';
import { MAX_EXPORTED_ROWS } from '../../../shared/constants/appConstants';

const WhiteTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'white !important',
    borderRadius: '6px',
    boxShadow: '0px 4px 12px 0px rgba(0, 0, 36, 0.25);',
    zIndex: 100,
  },
  arrow: {
    display: 'none',
  },
}))(Tooltip);

function OptionMulti(props) {
  const { isSelected, label, isFocused, data } = props;
  const { recTypes } = data;
  return (
    <components.Option {...props}>
      <div className={`${classes.option} ${isFocused ? classes.focused : null}`}>
        <input className={classes.checkbox} type="checkbox" checked={isSelected} onChange={() => null} />{' '}
        <Tooltip title={label}>
          <span className={`${classes.label} ${isSelected ? classes.activeOption : ''}`}>{label}</span>
        </Tooltip>
        {recTypes?.length > 0 && (
          <WhiteTooltip
            placement="bottom"
            title={
              <div className={classes.tagTooltipWrap}>
                {recTypes.map((tag) => (
                  <div key={`tagTP-${tag}`}>
                    <span className={classes.tagName}>{tag}</span>
                    <br />
                  </div>
                ))}
              </div>
            }
          >
            <div className={classes.chipWrapper}>
              <span className={classes.chip}>{`${recTypes.length} Types`}</span>
            </div>
          </WhiteTooltip>
        )}
      </div>
    </components.Option>
  );
}
OptionMulti.propTypes = {
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
};

const RecommendationsTableHeader = ({
  rowsCount,
  displayCount,
  searchText,
  setSearchText,
  showFilters,
  setShowFilters,
  isExpanded,
  setIsExpanded,
  fetchExportData,
  columns,
  hiddenColumnNames,
  setHiddenColumnNames,
  pageChange,
  currentPage,
  totalPages,
  isDataExporting,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  useEffect(() => {
    setOptions(
      columns.map((column) => ({
        label: column.title,
        value: column.name,
        recTypes: column.recTypes,
      })),
    );
    setSelectedValues(
      columns
        .filter((column) => !hiddenColumnNames.includes(column.name))
        .map((column) => ({
          label: column.title,
          value: column.name,
        })),
    );
  }, [columns]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const selectMultiItem = document.querySelector('#select-multi-item');
      if (selectMultiItem && !selectMultiItem.contains(event.target)) {
        setAnchorEl(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className={classes.header}>
      <div>
        <Button
          automationId="expand-rows"
          text=""
          isSecondary
          icon={() => <GenerateIcon iconName={isExpanded ? ICONS.collapse.name : ICONS.expand.name} />}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        />
        <span className={classes.headerText}>Recommendations</span>
        <span className={classes.count}>
          {`(${displayCount && displayCount !== rowsCount ? `${displayCount}/${rowsCount}` : rowsCount || ''})`}
        </span>
      </div>

      <div className={classes.rightMenu}>
        <div className="position-relative d-flex align-items-center">
          <Input
            className={classes.input}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search"
            name="search-recommendations"
            isSearch
            searchComponent={<GenerateIcon iconName={ICONS.search.name} />}
            type="text"
          />
        </div>
        {/* <Tooltip title="Group by columns" arrow> */}
        {/*  <Button */}
        {/*    text="Group by type" */}
        {/*    automationId="group-by-type" */}
        {/*    onClick={() => {}} */}
        {/*    isSecondary */}
        {/*    icon={() => <GenerateIcon iconName={ICONS.layerGroup.name} />} */}
        {/*  /> */}
        {/*  <span /> */}
        {/* </Tooltip> */}
        <div>
          <Tooltip title="Show Columns Chooser" arrow>
            <Button
              text="Columns"
              automationId="chooser-columns"
              onClick={() => setAnchorEl(!anchorEl)}
              isSecondary
              icon={() => <GenerateIcon iconName={ICONS.penToSquare.name} />}
            />
            <span />
          </Tooltip>
          <div style={{ zIndex: 10, position: 'absolute' }} id="select-multi-item">
            <SelectMultiItem
              isOpen={anchorEl}
              options={options}
              onChange={(values) => {
                setSelectedValues(values);
                const newHidden = columns
                  .filter((column) => !values.find((v) => v.value === column.name))
                  .map((column) => ({
                    label: column.title,
                    value: column.name,
                  }));
                setHiddenColumnNames(newHidden.map((column) => column.value));
              }}
              selected={selectedValues}
              onMenuBlur={(e) => {
                const columnsButton = document.querySelector('[automation-id="chooser-columns"]');
                if (e.relatedTarget !== columnsButton) {
                  setAnchorEl(false);
                }
              }}
              customOptionMultiComponent={OptionMulti}
              hideFooter
              width={380}
            />
          </div>
        </div>
        <Tooltip title="Filter columns value" arrow>
          <Button
            text="Filter Results"
            automationId="filter-results"
            onClick={() => {
              setShowFilters(!showFilters);
            }}
            overrideStyles={showFilters ? { background: '#DFE9FF' } : {}}
            isSecondary
            icon={() => <GenerateIcon iconName={showFilters ? ICONS.xMark.name : ICONS.filterList.name} />}
          />
          <span />
        </Tooltip>
        <Tooltip
          title={
            rowsCount > MAX_EXPORTED_ROWS
              ? 'You can export up to 100,000 recommendation records, please refine your filters to enable export'
              : 'Export'
          }
          arrow
        >
          <CustomCSVDownload
            fetchData={fetchExportData}
            automationId="export-recommendations"
            showDownloadIcon
            isLoading={isDataExporting}
            filesNumber={1}
            disabled={rowsCount > MAX_EXPORTED_ROWS}
            isSecondary
            isLightButton
            hideText
            style={{
              height: 36,
            }}
          />
          <span />
        </Tooltip>
        <Pagination currPageIdx={currentPage} onPageChange={pageChange} totalPages={totalPages} />
      </div>
    </div>
  );
};

RecommendationsTableHeader.propTypes = {
  rowsCount: PropTypes.number.isRequired,
  displayCount: PropTypes.number.isRequired,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func.isRequired,
  showFilters: PropTypes.bool,
  setShowFilters: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func.isRequired,
};
RecommendationsTableHeader.defaultProps = {
  searchText: null,
  showFilters: false,
  isExpanded: false,
};

export default RecommendationsTableHeader;
