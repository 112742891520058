import React, { useEffect, useState } from 'react';
import { SelectMultiItem } from '@pileus-cloud/anodot-frontend-common/dist';
import { DROPDOWN_MAIN_FILTERS, FILTERS } from 'recommendationsNew/consts';
import useDynamicFilter from 'recommendationsNew/hooks/react-query/useDynamicFilter';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { isEmpty } from 'lodash';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import resetFilterImg from 'recommendationsNew/img/reset-filter.svg';
import { SelectMultiItem as SelectIconsMultiItem } from '../servicesFilter/selectMultiMenuItem/SelectMultiMenu';
import { AdditionalFilters } from '../additionalFilters/additionalFilters';
import { FilterWrapper } from './filterWrappers';
import HeatMapSelectedFiltersDisplay from '../displayedSelectedFilters/heatMapSelectedFiltersDisplay';
import { DateRangeFilter } from '../dateRangeFilter/dateRangeFilter';
import HeatMapActions from '../../heatMapActions/heatMapActions';
import { StatusFilter } from '../statusFilter/statusFilter';
import { ToggleWasteChart } from '../wasteChartToggle/toggleWasteChart';
import { getFiltersForDynamicFilterUnselectedValues, getInitialFiltersStateCopy } from '../filterStates';
import { HeatMapActionsProvider } from '../../contexts/heatMapActionsContext';
import { AnnualSavingsMinValueFilter } from '../additionalFilters/annualSavingsMinValueFilter';
import classes from './selectMultiButton.module.scss';

const HeatMapFilters = () => {
  const [currentFilter, setCurrentFilter] = useState();
  const {
    recommendationFilters: filtersContext,
    setRecommendationFilters: setFiltersContext,
    isDashboardPanel,
    isFiltersChanged,
  } = useRecommendationsNewContext();

  const dynamicFilterHook = useDynamicFilter(currentFilter);
  const {
    data: dataFull,
    isLoading: isDataFullLoading,
    isFetching: isDataFullFetching,
  } = dynamicFilterHook.fetchDynamicFilter(getFiltersForDynamicFilterUnselectedValues(filtersContext));

  const [localFilters, setLocalFilters] = useState({
    [FILTERS.CATEGORIES.id]: [],
    [FILTERS.TYPES.id]: [],
    [FILTERS.SERVICES.id]: [],
  });

  useEffect(() => {
    setLocalFilters({
      [FILTERS.CATEGORIES.id]:
        currentFilter === FILTERS.CATEGORIES.id && isEmpty(filtersContext[FILTERS.CATEGORIES.id])
          ? dataFull?.page
          : filtersContext[FILTERS.CATEGORIES.id],
      [FILTERS.TYPES.id]:
        currentFilter === FILTERS.TYPES.id && isEmpty(filtersContext[FILTERS.TYPES.id])
          ? dataFull?.page
          : filtersContext[FILTERS.TYPES.id],
      [FILTERS.SERVICES.id]:
        currentFilter === FILTERS.SERVICES.id && isEmpty(filtersContext[FILTERS.SERVICES.id])
          ? dataFull?.page
          : filtersContext[FILTERS.SERVICES.id],
    });
  }, [dataFull?.page, filtersContext, currentFilter]);

  const onSelectMultiButtonClick = (isMenuOpen, filterId) => {
    if (isMenuOpen && filterId !== currentFilter) {
      setCurrentFilter(filterId);
    } else if (!isMenuOpen) {
      setCurrentFilter(undefined);
    }
  };

  const handleSelectionChange = (currentFilterSelectedOptions) => {
    if (currentFilter) {
      const currentFilterTmp = currentFilter;
      setCurrentFilter(undefined);
      const newFilters = {
        ...filtersContext,
        [currentFilterTmp]:
          currentFilterSelectedOptions?.length === dataFull?.page?.length ? [] : currentFilterSelectedOptions,
      };
      setFiltersContext(newFilters);
    }
  };

  const handleUserStatusChange = (userStatus) => {
    const newFilters = {
      ...filtersContext,
      [FILTERS.IS_OPEN.id]: userStatus.isOpen,
      [FILTERS.USER_STATUS.id]: userStatus.status,
    };
    setFiltersContext(newFilters);
  };

  const getDynamicFilterOptions = (filterId) => {
    if (dataFull?.page && currentFilter === filterId) {
      return dataFull?.page;
    }
    return undefined;
  };

  const selectCategoryMultiMenuComponentGetter = (onSelectionChange, selectedOptions, isSelectMenuOpen, onMenuBlur) => (
    <SelectMultiItem
      isOpen={isSelectMenuOpen}
      options={getDynamicFilterOptions(FILTERS.CATEGORIES.id)}
      onChange={onSelectionChange}
      selected={selectedOptions}
      onMenuBlur={onMenuBlur}
      isLoading={(isDataFullLoading || isDataFullFetching) && currentFilter === FILTERS.CATEGORIES.id}
      width="290px"
    />
  );

  const selectTypesMultiMenuComponentGetter = (onSelectionChange, selectedOptions, isSelectMenuOpen, onMenuBlur) => (
    <SelectMultiItem
      isOpen={isSelectMenuOpen}
      options={getDynamicFilterOptions(FILTERS.TYPES.id)}
      onChange={onSelectionChange}
      selected={selectedOptions}
      onMenuBlur={onMenuBlur}
      isLoading={(isDataFullLoading || isDataFullFetching) && currentFilter === FILTERS.TYPES.id}
      width="320px"
    />
  );

  const selectServicesMultiMenuComponentGetter = (
    onSelectionChange,
    selectedServices,
    isSelectMenuOpen,
    onMenuBlur,
    controlProps,
  ) => (
    <SelectIconsMultiItem
      containerProps={controlProps}
      isOpen={isSelectMenuOpen}
      isIconsViewEnabled
      onChange={onSelectionChange}
      onMenuBlur={onMenuBlur}
      options={getDynamicFilterOptions(FILTERS.SERVICES.id)}
      selected={selectedServices}
      width="295px"
      isLoading={(isDataFullLoading || isDataFullFetching) && currentFilter === FILTERS.SERVICES.id}
    />
  );

  const getFullRecTypesUrl = () => {
    const { usersStore } = useRootStore();
    const { currDispUserCloudAccountType } = usersStore;
    switch (currDispUserCloudAccountType) {
      case CLOUD_TYPE_IDS.AWS:
        return 'https://cloudcost.anodot.com/hc/en-us/articles/7017727629596-Recommendations-for-AWS';
      case CLOUD_TYPE_IDS.AZURE:
        return 'https://cloudcost.anodot.com/hc/en-us/articles/7017727826716-Recommendations-for-Azure';
      case CLOUD_TYPE_IDS.GCP:
        return 'https://cloudcost.anodot.com/hc/en-us/articles/7017727991964-Recommendations-for-GCP';
      default:
        return 'https://cloudcost.anodot.com/hc/en-us/articles/7017727629596-Recommendations-for-AWS';
    }
  };

  const fullRecTypesTooltip = (
    <span>
      <a target="_blank" href={getFullRecTypesUrl()} rel="noreferrer">
        {`Click here `}
      </a>
      to view the full recommendation list offered by Anodot
    </span>
  );

  return (
    <HeatMapActionsProvider>
      <div className="d-flex flex-column">
        {!isDashboardPanel && (
          <div className={`d-flex flex-row justify-content-between px-3 py-2 align-items-center ${classes.flexWrap}`}>
            <div className={`d-flex flex-row ${classes.filterWrapper}`}>
              <ToggleWasteChart />
              <FilterWrapper
                allFilters={localFilters}
                filterDataLength={dataFull?.page?.length}
                filterType={DROPDOWN_MAIN_FILTERS.CATEGORIES}
                handleSelectionChange={handleSelectionChange}
                onSelectMultiButtonClick={onSelectMultiButtonClick}
                selectFilterMultiMenuComponentGetter={selectCategoryMultiMenuComponentGetter}
              />
              <FilterWrapper
                allFilters={localFilters}
                filterDataLength={dataFull?.page?.length}
                filterType={DROPDOWN_MAIN_FILTERS.TYPES}
                handleSelectionChange={handleSelectionChange}
                onSelectMultiButtonClick={onSelectMultiButtonClick}
                selectFilterMultiMenuComponentGetter={selectTypesMultiMenuComponentGetter}
                tooltip={fullRecTypesTooltip}
              />
              <FilterWrapper
                allFilters={localFilters}
                filterDataLength={dataFull?.page?.length}
                filterType={DROPDOWN_MAIN_FILTERS.SERVICES}
                handleSelectionChange={handleSelectionChange}
                onSelectMultiButtonClick={onSelectMultiButtonClick}
                selectFilterMultiMenuComponentGetter={selectServicesMultiMenuComponentGetter}
              />
              <div className="d-flex flex-column" automation-id="heatmap-date-range">
                <DateRangeFilter />
              </div>
              <div className="d-flex flex-column" automation-id="heatmap-user-status">
                <StatusFilter onUserStatusChange={handleUserStatusChange} />
              </div>
              <div className="d-flex flex-column" automation-id="heatmap-additional-filters">
                <AnnualSavingsMinValueFilter automation-id="heatmap-annual-savings-filter" />
              </div>
              <div className="d-flex flex-column" automation-id="heatmap-additional-filters">
                <AdditionalFilters />
              </div>
              {isFiltersChanged && (
                <div className="d-flex flex-column" automation-id="heatmap-additional-filters">
                  <Tooltip title="Reset all filters to default" placement="top" automation-id="heatmap-reset-filters">
                    <div
                      automation-id="reset-filter"
                      className={classes.resetWrapper}
                      onClick={() => setFiltersContext(getInitialFiltersStateCopy())}
                    >
                      <img className={classes.resetIcon} src={resetFilterImg} alt="reset filter" />
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
            <div className={classes.actionsWrapper}>
              <HeatMapActions />
            </div>
          </div>
        )}
        <HeatMapSelectedFiltersDisplay unsetCurrentFilter={() => setCurrentFilter(undefined)} />
      </div>
    </HeatMapActionsProvider>
  );
};

export default HeatMapFilters;
