import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import React from 'react';
import { AwsCommonFields, awsCommonFieldToDisplayField } from 'shared/constants/awsConstants';
import styles from './billingRuleDetails.module.scss';

const iconNameByFilterName = {
  [AwsCommonFields.SERVICE]: ICONS.cloud.name,
  [AwsCommonFields.REGION]: ICONS.earth.name,
  [AwsCommonFields.OPERATION]: ICONS.fileLinesLight.name,
  default: ICONS.gearsRegular.name,
};
const BillingRuleDetails = ({ row: billingRule }) => {
  // const filtersToDisplay = billingRule.filters.reduce(
  //   (accFilters, filter) => [
  //     ...accFilters,
  //     ...filter.map((subFilter) => ({
  //       field: awsCommonFieldToDisplayField.get(subFilter.field),
  //       values: subFilter.operation.includes('not') ? (
  //         <span style={{ color: 'red' }}>{subFilter.values.map((v) => `${v} (Excluded)`)}</span>
  //       ) : (
  //         <span>{subFilter.values.join(', ')}</span>
  //       ),
  //       iconName: iconNameByFilterName[subFilter.field],
  //     })),
  //   ],
  //   [],
  // );
  const defaultFilter = (field) => ({
    field,
    displayName: awsCommonFieldToDisplayField.get(field),
    value: 'All',
    iconName: iconNameByFilterName[field] || iconNameByFilterName.default,
  });

  const filtersToDisplay = [
    ...Object.entries(billingRule.filters.includeFilters || {}).map(
      ([field, values]) => ({
        ...defaultFilter(field),
        value:
          typeof values === 'object' ? values.join(billingRule.filters.conjunction?.[field] ? ' Or ' : ', ') : values,
      }),
      [],
    ),
    ...Object.entries(billingRule.filters.excludeFilters || {}).map(
      ([field, values]) => ({
        ...defaultFilter(field),
        value: (
          <span className={styles.excluded}>
            {typeof values === 'object'
              ? values.map((v) => `${v} (Excluded)`).join(billingRule.filters.conjunction?.[field] ? ' Or ' : ', ')
              : `${values} (Excluded)`}
          </span>
        ),
      }),
      [],
    ),
  ];

  const FilterComponent = ({ filter }) => (
    <div key={filter.displayName}>
      <GenerateIcon iconName={filter.iconName} /> {filter.displayName} <span>{filter.value}</span>
    </div>
  );

  return (
    <div className={styles.billingRuleDetailsContainer}>
      <div>
        <GenerateIcon iconName={ICONS.mainUserRegular.name} /> Customer{' '}
        <span>{billingRule.scope.customerNames.join(', ') || 'All'}</span>
      </div>
      <FilterComponent
        filter={
          filtersToDisplay.find(({ field }) => field === AwsCommonFields.SERVICE) ||
          defaultFilter(AwsCommonFields.SERVICE)
        }
      />
      <div>
        <GenerateIcon iconName={ICONS.fileRegular.name} /> Description{' '}
        <span title={billingRule.description.ruleDescription}>{billingRule.description.ruleDescription}</span>
      </div>
      <FilterComponent
        filter={
          filtersToDisplay.find(({ field }) => field === AwsCommonFields.REGION) ||
          defaultFilter(AwsCommonFields.REGION)
        }
      />
      {filtersToDisplay
        .filter(({ field }) => ![AwsCommonFields.SERVICE, AwsCommonFields.REGION].includes(field))
        ?.map((filter) => (
          <FilterComponent filter={filter} />
        ))}
    </div>
  );
};

export default BillingRuleDetails;
