import React, { useMemo } from 'react';
import HeatMapSummaryItem from 'recommendationsNew/components/heatMap/heatMapSummaryItem';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import useHeatMapSummary from 'recommendationsNew/hooks/react-query/useHeatMapSummary';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import { ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { palette } from 'shared/constants/colorsConstants';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import { STATUS_FILTER_PRESETS } from 'recommendationsNew/consts';
import {
  ActualSavingsStatusFilterOption,
  ExcludedStatusFilterOption,
  PotentialSavingsStatusFilterOption,
} from './heatMapFilters/statusFilter/statusFilterOptions';

import classes from './heatMap.module.scss';
import { useFilterStatus } from '../../hooks/useFilterStatus';

const HeatMapSummary = () => {
  const { currencySymbol } = useUserSettingsContext();

  const { recommendationFilters: filtersContext, isDashboardPanel } = useRecommendationsNewContext();

  const heatmapSummaryHook = useHeatMapSummary();
  const { data: summary } = heatmapSummaryHook.fetchHeatMapSummary(filtersContext);
  const statusFilter = useFilterStatus(filtersContext);
  const summaryData = useMemo(() => {
    if (!summary) {
      return [];
    }
    const potentialSummary = {
      main: {
        title: 'Annual Potential Savings',
        number: summary?.potentialAnnualSavings || 0,
        unit: currencySymbol,
        isDashboardPanel: true,
        tooltip: 'Open recommendation sum & count',
        count: summary?.potentialSavingsRecommendationCount,
        isPotentialValue: true,
      },
      additional: {
        icon: ICONS.gaugeCircleBolt.name,
        title: 'ExSR',
        value: summary?.expectedSavingsRatePercent || 0,
        isValueColored: true,
        unit: '%',
      },
    };
    const actualSummary = {
      main: {
        title: 'Annual Actual Savings',
        number: summary?.actualAnnualSavings || 0,
        unit: currencySymbol,
        tooltip: 'Completed/Done recommendation sum & count',
        count: summary?.actualSavingsRecommendationCount,
      },
      additional: {
        icon: ICONS.gaugeCircleBolt.name,
        title: 'ESR',
        value: summary?.effectiveSavingsRatePercent || 0,
        isValueColored: true,
        unit: '%',
      },
    };

    const excludeSummary = {
      additional: {
        icon: ICONS.excluded.name,
        title: 'Excluded',
        value: summary?.excludedRecommendationsSavings,
        isValueColored: false,
        iconColor: palette.red[500],
        isUnitOnTheLeft: true,
        unit: currencySymbol,
        count: summary?.excludedRecommendationsCount,
        tooltip: 'Excluded recommendation sum & count',
      },
    };
    const summaryItems = [];

    if (statusFilter.isOpen) {
      summaryItems.push(potentialSummary);
    }
    if (statusFilter.isCompleted || statusFilter.isDone) {
      summaryItems.push(actualSummary);
    }
    if (statusFilter.isExclude) {
      summaryItems.push(excludeSummary);
    }
    return summaryItems;
  }, [summary]);

  return (
    <div className={`${classes.summary} ${isDashboardPanel && classes.flexStart}`}>
      {summary ? (
        <>
          {summaryData.map((item) => (
            <HeatMapSummaryItem summaryItem={item} />
          ))}
          <InfoPopover isSimple={false}>
            <div className={classes.infoTooltipContainer}>
              <div className={classes.infoTooltipTitle}>
                The potential and actual savings summarize recommendations according to this table. On top of the
                filters you have applied.
              </div>
              <div className={classes.infoTooltipContent}>
                <div className={classes.option}>
                  <div className={classes.optionTitle}>{STATUS_FILTER_PRESETS.POTENTIAL_SAVINGS.label}</div>
                  <PotentialSavingsStatusFilterOption />
                </div>
                <div className={classes.option}>
                  <div className={classes.optionTitle}>{STATUS_FILTER_PRESETS.ACTUAL_SAVINGS.label}</div>
                  <ActualSavingsStatusFilterOption />
                </div>
                <div className={classes.option}>
                  <div className={classes.optionTitle}>{STATUS_FILTER_PRESETS.EXCLUDED.label}</div>
                  <ExcludedStatusFilterOption />
                </div>
              </div>
              <div className={classes.infoTooltipLegend}>
                <div>ExSR (Expected Savings Rate) = Potential Savings/Current Cost</div>
                <div>ESR (Effective Savings Rate) = Actual Savings/Current Cost</div>
              </div>
            </div>
          </InfoPopover>
        </>
      ) : null}
    </div>
  );
};

export default HeatMapSummary;
