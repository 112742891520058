/* eslint-disable max-len */
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { ReactComponent as AWSSquare } from '../img/cloud-providers/aws-square.svg';
import AzureSquare from '../img/cloud-providers/azure-square';
import { ReactComponent as AzureSimple } from '../img/cloud-providers/simple/azure.svg';
import { ReactComponent as AWSSimple } from '../img/cloud-providers/simple/aws.svg';
import { ReactComponent as GCPSimple } from '../img/cloud-providers/simple/gcp.svg';
import { ReactComponent as MultiSimple } from '../img/cloud-providers/simple/multi.svg';
import { ReactComponent as GCPSquare } from '../img/cloud-providers/gcp-square.svg';
import { ReactComponent as MulticloudSquare } from '../img/cloud-providers/multi-square.svg';

export class PageNames {
  static ANODOT_ADMIN_PANEL = 'Admin Control Panel';
  static AWS_RESELLER = 'Reseller';
  static REC_HISTORY = 'Recommendations History';
  static DASHBOARD = 'Dashboard';
  static ORGANIZATION = 'Organization';
  static AZURE_RI_COVERAGE = 'RI Coverage';
  static RECOMMENDATION_DASHBOARD = 'Recommendations Dashboard';
  static MANAGE_RECOMMENDATIONS = 'Recommendations Exclusions'; // 'Manage Recommendations';
  static DAILY_RECOMMENDATION = 'Daily Recommendations';
  static EC2_USAGE = 'EC2 Usage';
  static S3_USAGE = 'S3 Usage';
  static RDS_USAGE = 'RDS Usage';
  static BUDGET = 'Budget';
  static COST_AND_USAGE_EXPLORER = 'Cost & Usage Explorer';
  static METRICS_EXPLORER = 'Metrics Explorer';
  static CUE_ALERTS = 'Alerts';
  static ANOMALY_DETECTION = 'Anomaly Detection';
  static RESOURCE_EXPLORER = 'Resource Explorer';
  static REPORTS = 'Saved Reports';
  static RECOMMENDATIONS_REPORTS = 'Recommendations Reports';
  static RECOMMENDATIONS_PREFERENCES = 'Preferences';
  static WELCOME = 'Welcome to Cloud Cost';
  static ACCOUNT = 'Account';
  static FEATURE_FLAGS = 'Feature Flags';
  static AZURE_ON_BOARDING = 'Connect your Azure Account';
  static GCP_ON_BOARDING = 'Connect your GCP Account';
  static NOTIFICATIONS = 'Notifications';
  static SAVINGS_PLANS_ANALYZER = 'Savings Plans Analyzer';
  static BIG_QUERY = 'BigQuery Audit';
  static GcpComputeEngine = 'Compute Engine';
  static GcpAppEngine = 'App Engine';
  static GcpCloudSQL = 'Cloud SQL';
  static AZURE_STORAGE_USAGE = 'Block Blob Storage Usage';
  static AZURE_VIRTUAL_MACHINES_USAGE = 'Virtual Machines Usage';
  static AZURE_DB_COSTS_USAGE = 'SQL DB Costs';
  static AzureDataLake = 'Azure Data Lake';
  static AzureDataFactory = 'Azure Data Factory';
  static AzureSQL = 'SQL Database';
  static CUSTOMERS = 'Billing Status';
  static CUSTOMERS_MANAGE = 'Manage Customers';
  static CUSTOM_DASHBOARD = 'Dashboards';
  static CUSTOM_DASHBOARD_TEMPLATE = 'Templates';
  static CUSTOM_DASHBOARDS_PANELS = 'Panels';
  static CUSTOMERS_HISTORY = 'Billing History';
  static CUSTOMERS_REPORTS = 'Reports';
  static RI_UTILIZATION = 'RI Utilization';
  static SAVINGS_PLANS_UTILIZATION = 'Savings Plans Utilization';
  static COMMITMENT_DASHBOARD = 'Commitment Dashboard';
  static BILLING_RULES_NEW = 'Billing Rules';
  static AWS_BILLIBG_RULES = 'Billing Rules';
  static AZURE_BILLIBG_RULES = 'Billing Rules';
  static EBS_USAGE = 'EBS Usage';
  static IDLE_INSTANCES = 'Idle Instances';
  static K8S_PREVIEW = 'Kubernetes';
  static K8S_USAGE = 'Kubernetes Cost & Usage Explorer';
  static K8S_ASSETS = 'Kubernetes Assets';
  static K8S_PREFERENCES = 'Kubernetes Preferences';
  static AWS_CREDITS = 'Credits';
  static VIRTUAL_TAGS = 'Virtual Tags';
  static FILTER_GROUP = 'Filter Group';
  static BUSINESS_MAPPING = 'Business Mapping Viewpoints';
  static BUSINESS_MAPPING_K8S = 'Business Mapping Kubernetes Viewpoints';
  static ACCOUNT_TAGS = 'Enrichment Tags';
  static VIEWS = 'Views';
  static ASSETS = 'Assets';
  static REBILLING_PREFERENCES = 'Partner - Preferences';
  static RECOMMENDATION_360 = 'Recommendation 360°';
  static RECOMMENDATION_EXPLORER = 'Waste Detector';
  static BILLING_SUMMARY = 'Billing Summary';
}

export class ModelNames {
  static USERS_MODEL = 1;
  static CUSTOMERS_MODEL = 18;
  static COMMITMENT_MODEL = 19;
  static CUE_ALERTS_MODEL = 24;
  static K8S_USAGE_MODEL = 25;
  static CUSTOM_DASHBOARD_MODEL = 26;
  static ROLES_MODEL = 29;
}

export class AppCommonFields {
  static USAGE_DATE = 'usageDate';
  static GROUP_BY = 'groupBy';
  static TOTAL_COST = 'totalCost';
  static TOTAL_USAGE = 'totalUsage';
  static TOTAL_USAGE_SECONDARY = 'totalUsageSecondary';
  static TOTAL_QUANTITY = 'totalQuantity';
}

export class GranularityLevelsTypes {
  static GRAN_LEVEL_YEARLY = 'year';
  static GRAN_LEVEL_QUARTERLY = 'quarter';
  static GRAN_LEVEL_MONTHLY = 'month';
  static GRAN_LEVEL_WEEKLY = 'week';
  static GRAN_LEVEL_DAILY = 'day';
  static GRAN_LEVEL_HOURLY = 'hour';
}

export const mapGranularityLevelsTypesToDisplayValue = new Map([
  [GranularityLevelsTypes.GRAN_LEVEL_YEARLY, 'Yearly'],
  [GranularityLevelsTypes.GRAN_LEVEL_QUARTERLY, 'Quarterly'],
  [GranularityLevelsTypes.GRAN_LEVEL_MONTHLY, 'Monthly'],
  [GranularityLevelsTypes.GRAN_LEVEL_DAILY, 'Daily'],
  [GranularityLevelsTypes.GRAN_LEVEL_WEEKLY, 'Weekly'],
]);

export const StatementDownloadTypes = {
  PDF: 'PDF',
  CSV: 'CSV',
};

export const cloudTypeToIcon = {
  [CLOUD_TYPE_IDS.AWS]: AWSSquare,
  [CLOUD_TYPE_IDS.AZURE]: AzureSquare,
  [CLOUD_TYPE_IDS.GCP]: GCPSquare,
  [CLOUD_TYPE_IDS.MULTI]: MulticloudSquare,
};

export const cloudTypeToSimpleIcon = {
  [CLOUD_TYPE_IDS.AWS]: AWSSimple,
  [CLOUD_TYPE_IDS.AZURE]: AzureSimple,
  [CLOUD_TYPE_IDS.GCP]: GCPSimple,
  [CLOUD_TYPE_IDS.MULTI]: MultiSimple,
};

export const OPERATORS = {
  IS: 'Is',
  IS_NOT: 'Is not',
  LIKE: 'Contains',
  NOT_LIKE: 'Not Contains',
  STARTS_WITH: 'Starts with',
  ENDS_WITH: 'Ends with',
};

export const OPERATORS_KEYS = {
  IS: 'IS',
  IS_NOT: 'IS_NOT',
  LIKE: 'LIKE',
  NOT_LIKE: 'NOT_LIKE',
  STARTS_WITH: 'STARTS_WITH',
  ENDS_WITH: 'ENDS_WITH',
};

export const CURRENCY_DETAILS = {
  USD: 'USD',
  SAVING_CALCULATE_TOOLTIP:
    'The saving is calculated based on the currency exchange rate corresponding to the invoice date',
};
export const isOperatorSelect = (op) => [OPERATORS_KEYS.IS_NOT, OPERATORS_KEYS.IS].includes(op);
export const isOperatorInput = (op) => !isOperatorSelect(op);
export const MAX_EXPORTED_ROWS = 100000;
