import React, { useEffect, useState } from 'react';
import {
  DataTypeProvider,
  Column,
  IntegratedSelection,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { Grid, TableHeaderRow, TableRowDetail, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import TableWrapper from 'shared/components/tables/TableWrapper';
import useTable from 'shared/hooks/customHooks/useTable';
import classes from './billingHistory.module.scss';
import {
  CurrencyNumberWithTooltipFormatter,
  CustomHeaderCell,
  CustomNoDataComponent,
  CustomSelectionCell,
  CustomToggleCell,
} from '../CustomTableComponents';
import CustomersStatus from '../CustomerDetails';
import { BillingHistoryRow } from '../../types.ts';

type Props = {
  rows: BillingHistoryRow[];
  selectedRows: number[];
  setSelectedRows(rs: number[]): void;
  tableColumns: Column[];
  isAllExpanded: boolean;
  timeRange: [Date, Date];
};

const CustomersHistoryTable: React.FC<Props> = ({
  rows,
  selectedRows,
  setSelectedRows,
  tableColumns,
  isAllExpanded,
  timeRange,
}) => {
  const [expandedRowIds, setExpandedRowIds] = useState<(string | number)[]>([]);
  const { NewTableRow, NewTableSelectionBar } = useTable();

  useEffect(() => {
    if (!isAllExpanded) {
      setExpandedRowIds([]);
    } else {
      setExpandedRowIds(rows.map((row, index) => index));
    }
  }, [isAllExpanded]);

  const columnsToApplyCurrencyFormatting = [
    'mtdResellerCost',
    'mtdCustomerCost',
    'partnerMargin',
    'riMargin',
    'usageMargin',
    'riFlexibilityMargin',
    'spFlexibilityMargin',
    'publicPriceMargin',
    'billingRuleMargin',
    'creditMargin',
    'resellerCredit',
  ];

  return (
    <Grid rows={rows} columns={tableColumns}>
      <SortingState
        defaultSorting={[
          { columnName: 'year', direction: 'desc' },
          { columnName: 'month', direction: 'desc' },
          { columnName: 'mtdResellerCost', direction: 'desc' },
          { columnName: 'mtdCustomerCost', direction: 'desc' },
          { columnName: 'partnerMargin', direction: 'desc' },
        ]}
      />
      <SelectionState selection={selectedRows} onSelectionChange={setSelectedRows} />
      <IntegratedSelection />
      <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={(n) => setExpandedRowIds(n)} />
      <DataTypeProvider
        for={columnsToApplyCurrencyFormatting}
        formatterComponent={CurrencyNumberWithTooltipFormatter}
      />
      <TableWrapper
        virtual
        noDataCellComponent={CustomNoDataComponent}
        rowComponent={(props) => (
          <NewTableRow expandedRowIds={expandedRowIds} setExpandedRowIds={setExpandedRowIds} {...props} />
        )}
        height="auto"
      />
      <TableSelection showSelectAll cellComponent={CustomSelectionCell} />
      <TableHeaderRow showSortingControls cellComponent={CustomHeaderCell} />
      <TableRowDetail
        contentComponent={(d) => <CustomersStatus row={d.row} timeRange={timeRange} />}
        toggleCellComponent={CustomToggleCell}
      />
      <NewTableSelectionBar selectedRows={selectedRows} counterClass="">
        <div className={classes.bulkRow}>
          {/* <BulkOperationsRow */}
          {/*  exportCsv={() => fetchDataForExport(true)} */}
          {/*  d selectedRecs={recommendationsData.filter((r, index) => selectedRows.includes(index))} */}
          {/* /> */}
        </div>
      </NewTableSelectionBar>
    </Grid>
  );
};

export default CustomersHistoryTable;
