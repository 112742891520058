import React, { useCallback, useMemo, useState } from 'react';
import useTable from 'shared/hooks/customHooks/useTable';
import { useRootStore } from 'app/contexts/RootStoreContext';
import Spinner from 'shared/components/andtComponents/Spinner';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import styles from './billingHistory.module.scss';
import CustomersHistoryTable from './Table.tsx';
import TableFilters from './TableFilters.tsx';
import useBillingInfo from '../../../../hooks/react-query/useBillingInfo';
import { getInitialDate } from '../../../../utils';
import { getTableColumnsAndWidths, LINES_IN_PAGE } from '../utils.ts';
import { BillingHistoryRow } from '../../types.ts';

const CustomersHistory = () => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [searchText, setSearchText] = useState('');
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [startDate, setStartDate] = useState<Date>(getInitialDate().startDate);
  const [endDate, setEndDate] = useState(getInitialDate().endDate);
  const { usersStore } = useRootStore();
  const { NewTableWrapper } = useTable();

  const { fetchBillingHistory } = useBillingInfo();
  const { data: historyData = [], isLoading } = fetchBillingHistory({
    startDate,
    endDate,
    cloudTypeId: usersStore.currDispUserCloudAccountType,
  });

  const onDatePickerChange = useCallback((startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  }, []);

  const filteredHistoryData = useMemo(
    () =>
      historyData.filter((row: BillingHistoryRow) =>
        searchText.length >= 2 ? row.customerName.includes(searchText) : true,
      ),
    [historyData, searchText],
  );

  if (isLoading) {
    return <Spinner />;
  }

  const { tableColumns } = getTableColumnsAndWidths(usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE);
  const rowsWithPagination = filteredHistoryData.slice(LINES_IN_PAGE * currentPage, LINES_IN_PAGE * (currentPage + 1));

  return (
    <div className={styles.recommendationList}>
      <NewTableWrapper className={selectedRows?.length > 0 && 'custom-pager'}>
        <TableFilters
          rowsCount={filteredHistoryData.length}
          searchText={searchText}
          setSearchText={setSearchText as () => void}
          isExpanded={isAllExpanded}
          setIsExpanded={setIsAllExpanded}
          onPageChange={(newPageIndex) => setCurrentPage(newPageIndex)}
          currentPage={currentPage}
          totalPages={Math.ceil(filteredHistoryData.length / LINES_IN_PAGE)}
          headerText={`Billing Status(${historyData.length})`}
          startDate={startDate}
          endDate={endDate}
          onDateChange={onDatePickerChange}
        />
        <div>
          <CustomersHistoryTable
            rows={rowsWithPagination}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            tableColumns={tableColumns}
            isAllExpanded={isAllExpanded}
            timeRange={[startDate, endDate]}
          />
        </div>
      </NewTableWrapper>
    </div>
  );
};

export default CustomersHistory;
