/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react';
import ShowPasswordIcon from 'mdi-react/EyeOutlineIcon';
import HidePasswordIcon from 'mdi-react/EyeOffOutlineIcon';
import Button from 'shared/components/andtComponents/Button';
import PropTypes from 'prop-types';
import Input from 'shared/components/andtComponents/Input';
import styles from './RegisterForm.module.scss';
import useRegistrationCode from '../../../new-user-management/hooks/reactQuery/useRegistrationCode';

const RegisterForm = ({
  handleSubmit,
  handleChange,
  jobTitle,
  companyName,
  companyExampleName,
  username,
  password,
  code,
  isNewUserManagement,
}) => {
  const [codeSent, setCodeSent] = useState(false);
  const [codeSentCounter, setCodeSentCounter] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const { sendCode } = useRegistrationCode();
  const { mutate: handleSendCode } = sendCode({
    onSuccess: () => {
      setCodeSent(true);
      setCodeSentCounter(60);
    },
  });
  useEffect(() => {
    if (codeSent) {
      const interval = setInterval(() => {
        setCodeSentCounter((prev) => Math.max(0, prev - 1));
      }, 1000);
      return () => clearInterval(interval);
    }
    return () => {};
  }, [codeSent]);
  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  const handleKeyPress = (target) => {
    if (target.charCode === 13) {
      handleSubmit();
    }
  };
  return (
    <form onSubmit={handleSubmit} className="w-100">
      <div>
        <label htmlFor="companyName" className={styles.input}>
          Company *
          <Input
            value={companyName}
            onChange={handleChange}
            name="companyName"
            type="text"
            placeholder={companyExampleName}
          />
        </label>
      </div>
      <div>
        <label htmlFor="jobTitle" className={styles.input}>
          Job Title
          <Input value={jobTitle} onChange={handleChange} name="jobTitle" type="text" placeholder="Manager" />
        </label>
      </div>
      <div>
        <label htmlFor="username" className={styles.input}>
          Email *
          <Input
            value={username}
            onChange={handleChange}
            name="username"
            type="email"
            placeholder={`user@${companyExampleName.toLowerCase()}.com`}
          />
        </label>
      </div>
      {!isNewUserManagement && (
        <div>
          <label htmlFor="username" className={styles.input}>
            Password *
            <Input
              onChange={handleChange}
              name="password"
              component="input"
              value={password}
              onKeyPress={handleKeyPress}
              type={showPassword ? 'text' : 'password'}
              placeholder="****"
            />
            <button type="button" className="btn-no-style" onClick={handleShowPassword}>
              {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
            </button>
          </label>
        </div>
      )}
      {isNewUserManagement && (
        <div>
          <label htmlFor="code" className={styles.input}>
            Confirm Code
            <Input
              onChange={handleChange}
              name="code"
              component="input"
              value={code}
              onKeyPress={handleKeyPress}
              type="text"
              disabled={!codeSent || !username}
              placeholder="XXXXXXX"
            />
            <Button
              text={codeSentCounter ? `Send Code (${codeSentCounter}s)` : 'Send Code'}
              isTextButton
              type="button"
              className={{ button: styles.codeButton }}
              onClick={() => handleSendCode({ email: username })}
              isLoading={sendCode.isLoading}
              disabled={!username || codeSentCounter > 0}
            />
          </label>
        </div>
      )}
    </form>
  );
};

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  jobTitle: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  companyExampleName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
};

export default RegisterForm;
