import { TableFilterRow, TableHeaderRow, TableRowDetail, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { ReactComponent as NotContains } from 'shared/img/icons/not-contain.svg';
import { ReactComponent as Contains } from 'shared/img/icons/contains.svg';
import { ReactComponent as Equal } from 'shared/img/icons/equal.svg';
import { ReactComponent as NotEqual } from 'shared/img/icons/not-equal.svg';
import { ReactComponent as GreaterThan } from 'shared/img/icons/greater-than.svg';
import { ReactComponent as LessThan } from 'shared/img/icons/less-than.svg';
import { ReactComponent as GreaterThanOrEqual } from 'shared/img/icons/greater-than-or-equal.svg';
import classNames from 'classnames';
import { FILTER_OPERATIONS } from './const';
import Tooltip from '../Tooltip';

import styles from './TableComponents.module.scss';

export const CustomFilterIcon = ({ type }) => {
  switch (type) {
    case FILTER_OPERATIONS.CONTAINS:
      return <Contains />;
    case FILTER_OPERATIONS.NOT_CONTAINS:
      return <NotContains />;
    case FILTER_OPERATIONS.EQUAL:
      return <Equal />;
    case FILTER_OPERATIONS.NOT_EQUAL:
      return <NotEqual />;
    case FILTER_OPERATIONS.GREATER_THAN:
      return <GreaterThan />;
    case FILTER_OPERATIONS.LESS_THAN:
      return <LessThan />;
    case FILTER_OPERATIONS.GREATER_THAN_OR_EQUAL:
      return <GreaterThanOrEqual />;
    default:
      return <Contains />;
  }
};

export const CustomHeaderCell = ({ column, ...restProps }) => {
  const { children } = restProps;

  return (
    <>
      <TableHeaderRow.Cell {...restProps} column={column}>
        <Tooltip title={column.title} disabled={!column?.title}>
          <span className={styles.headerList}>{column?.title ? children : null}</span>
        </Tooltip>
      </TableHeaderRow.Cell>
    </>
  );
};

export const CustomFilterRow = ({ children, ...restProps }) => (
  <TableFilterRow.Row {...restProps} className={`filterRow ${styles.filterRow}`}>
    {children}
  </TableFilterRow.Row>
);

export const CustomFormatterWithTooltip = ({ value, leftPadding, isSmallText = false, overrideStyles }) => (
  <div className={leftPadding ? 'pe-3' : ''}>
    <Tooltip title={value}>
      <div
        className={classNames({ [styles.smallerColumnTitle]: isSmallText }, 'text-truncate')}
        style={{ ...overrideStyles }}
      >
        {value}
      </div>
    </Tooltip>
  </div>
);

export const CustomClickableFormatter = ({ value, onClick }) => (
  <div onClick={onClick}>
    <Tooltip title={value}>
      <div className={styles.clickableValue}>{value}</div>
    </Tooltip>
  </div>
);

export const CustomFormatterWithTooltipAndComponent = ({ children, ...props }) => (
  <div
    className={classNames('d-flex align-items-center', {
      'gap-2': props.isLeftComponent,
      'justify-content-between': !props.isLeftComponent,
    })}
  >
    {props.isLeftComponent && children ? <>{children}</> : null}
    <CustomFormatterWithTooltip {...props} />
    {!props.isLeftComponent && children ? <>{children}</> : null}
  </div>
);

export const CustomToggleCell = ({ style, expanded, ...restProps }) => (
  <TableRowDetail.ToggleCell style={style} {...restProps} automation-id="expand-row-icon">
    <div>
      <IconButton>
        <ExpandMoreIcon style={{ transform: expanded ? 'rotate(180deg)' : 'none' }} />
      </IconButton>
    </div>
  </TableRowDetail.ToggleCell>
);

export const CustomSelectionCell = (props) => {
  const { row, ...restProps } = props;

  return <TableSelection.Cell {...restProps} row={row} automation-id="select-rec-column" />;
};

export const CustomFilterCell = ({ column, classes, actionColumns, ...restProps }) => {
  if ([...actionColumns].includes(column.name)) {
    return <TableFilterRow.Cell {...restProps}> </TableFilterRow.Cell>;
  }
  return <TableFilterRow.Cell {...restProps} className="filterPlaceHolder" automation-id="input-filter" />;
};
