import { ICONS } from '@pileus-cloud/anodot-frontend-common/dist';

export const STALE_TIME = 60 * 60 * 1000;

export const DEFAULT_PAGE_SIZE = 50;

export const PAGINATION_DIRECTION = { NEXT: 1, PREV: -1 };

export const TAB_PATHS = {
  USERS: 'users',
  ROLES: 'roles',
};

export const USER_STATUS_OPTIONS = { ENABLED: 'Enabled', DISABLED: 'Disabled' };

export const USERS_COLUMNS = {
  USER_NAME: {
    columnName: 'username',
    title: 'User Name',
    width: 250,
    sortingEnabled: false, // Sorting is not supported by KeyCloak. The results will be sorted by username ascending.
    direction: 'asc',
  },
  FIRST_NAME: {
    columnName: 'firstName',
    title: 'First Name',
    width: 150,
    sortingEnabled: false,
  },
  LAST_NAME: {
    columnName: 'lastName',
    title: 'Last Name',
    width: 150,
    sortingEnabled: false,
  },
  CUSTOMER: {
    columnName: 'customer',
    title: 'Customer',
    width: 170,
    sortingEnabled: false,
  },
  ROLES: {
    columnName: 'roles',
    title: 'Roles',
    width: 150,
    sortingEnabled: false,
  },
  CREATED_AT: {
    columnName: 'createdAt',
    title: 'Created At',
    width: 170,
    sortingEnabled: false,
  },
  LAST_LOGIN: {
    columnName: 'lastLogin',
    title: 'Last Login',
    width: 170,
    sortingEnabled: false,
  },
  USER_STATUS: {
    columnName: 'userStatus',
    title: '',
    width: 170,
    sortingEnabled: false,
    align: 'right',
  },
  IMPERSONATE: { columnName: 'impersonate', title: '', width: 30, sortingEnabled: false, align: 'right' },
  ACTIONS: { columnName: 'actions', title: '', width: 40, sortingEnabled: false, align: 'right' },
};

export const USER_ROLES_COLUMNS = {
  ROLE_NAME: {
    columnName: 'displayName',
    title: 'Role Name',
    width: 100,
    direction: 'desc',
    sortingEnabled: true,
  },
  ASSIGNED_AT: {
    columnName: 'assignedAt',
    title: 'Assigned At',
    width: 100,
    sortingEnabled: false,
    getCellValue: () => '2024-08-01T09:05:06.311Z',
  },
  ASSIGNED_BY: {
    columnName: 'assignedBy',
    title: 'Assigned By',
    width: 100,
    sortingEnabled: false,
    getCellValue: () => 'Display Name',
  },
  REMOVE_USER_ROLE: {
    columnName: 'removeUserRole',
    title: '',
    width: 200,
    align: 'right',
    sortingEnabled: false,
  },
};

export const USER_ACTIONS = {
  ASSIGN_ROLES: {
    id: 'assignRoles',
    label: 'Assign Roles',
    icon: ICONS.userGear.name,
    automationId: 'userMenuActions_AssignRole',
  },
  EDIT: { id: 'edit', label: 'Edit', icon: ICONS.edit.name, automationId: 'userMenuActions_Edit' },
  RESET_PASSWORD: {
    id: 'resetPassword',
    label: 'Reset Password',
    icon: ICONS.refresh.name,
    automationId: 'userMenuActions_ResetPassword',
  },
  DELETE: { id: 'delete', label: 'Delete', icon: ICONS.deleteRegular.name, automationId: 'userMenuActions_Delete' },
};

export const ROLES_COLUMNS = {
  ROLE_NAME: {
    columnName: 'displayName',
    title: 'Role Name',
    width: 300,
    sortingEnabled: false,
    getCellValue: (row) => row?.identifier?.displayName,
    direction: 'asc',
  },
  ROLE_ID: {
    columnName: 'id',
    title: 'Role Id',
    width: 300,
    sortingEnabled: false,
    getCellValue: (row) => row?.identifier?.id,
  },
  USERS_COUNT: {
    columnName: 'usersCount',
    title: 'Users',
    width: 100,
    sortingEnabled: false,
  },
  CREATED_AT: {
    columnName: 'createdAt',
    title: 'Created At',
    width: 170,
    sortingEnabled: false,
  },
  CREATED_BY: {
    columnName: 'createdBy',
    title: 'Created By',
    width: 170,
    sortingEnabled: false,
  },
  UPDATED_AT: {
    columnName: 'updatedAt',
    title: 'Updated At',
    width: 170,
    sortingEnabled: false,
  },
  UPDATED_BY: {
    columnName: 'updatedBy',
    title: 'Updated By',
    width: 170,
    sortingEnabled: false,
  },
  ADD_LINKED_ACCOUNTS: {
    columnName: 'addLinkedAccounts',
    title: '',
    width: 50,
    sortingEnabled: false,
    align: 'right',
  },
  ADD_USERS: {
    columnName: 'addUsers',
    title: '',
    width: 50,
    sortingEnabled: false,
    align: 'right',
  },
  ACTIONS: { columnName: 'actions', title: '', width: 40, sortingEnabled: false, align: 'right' },
};

export const ADD_USERS_TO_ROLE_MODAL_COLUMNS = {
  USER_NAME: { ...USERS_COLUMNS.USER_NAME, width: 200 },
  FIRST_NAME: { ...USERS_COLUMNS.FIRST_NAME, width: 100 },
  LAST_NAME: { ...USERS_COLUMNS.LAST_NAME, width: 100 },
};

export const ASSIGN_ROLES_MODAL_COLUMNS = {
  ROLE_NAME: { ...ROLES_COLUMNS.ROLE_NAME, width: 100 },
  ROLE_ID: { ...ROLES_COLUMNS.ROLE_ID, width: 100 },
};

export const ROLE_USERS_COLUMNS = {
  USER_NAME: { ...USERS_COLUMNS.USER_NAME, width: 200 },
  FIRST_NAME: { ...USERS_COLUMNS.FIRST_NAME, width: 100 },
  LAST_NAME: { ...USERS_COLUMNS.LAST_NAME, width: 100 },
  ROLES: { ...USERS_COLUMNS.ROLES, width: 200 },
  CREATED_AT: { ...USERS_COLUMNS.CREATED_AT, width: 170 },
  LAST_LOGIN: { ...USERS_COLUMNS.LAST_LOGIN, width: 170 },
  USER_STATUS: { ...USERS_COLUMNS.USER_STATUS, width: 170 },
  REMOVE_USER_ROLE: {
    columnName: 'removeRoleUser',
    title: '',
    width: 200,
    align: 'right',
    sortingEnabled: false,
  },
};

export const ROLE_SUB_ROLES_COLUMNS = {
  ROLE_NAME: { ...ROLES_COLUMNS.ROLE_NAME, getCellValue: (row) => row?.displayName, width: 200 },
  CREATED_AT: { ...ROLES_COLUMNS.CREATED_AT, width: 170 },
  CREATED_BY: { ...ROLES_COLUMNS.CREATED_BY, width: 170 },
  UPDATED_AT: { ...ROLES_COLUMNS.UPDATED_AT, width: 170 },
  UPDATED_BY: { ...ROLES_COLUMNS.UPDATED_BY, width: 170 },
  REMOVE_SUB_ROLE: {
    columnName: 'removeRoleSubRole',
    title: '',
    width: 200,
    align: 'right',
    sortingEnabled: false,
  },
};

export const ROLE_ACTIONS = {
  EDIT: { label: 'Edit', icon: ICONS.edit.name, automationId: 'roleMenuActions_Edit' },
  VIEW: { label: 'View', icon: ICONS.eye.name, automationId: 'roleMenuActions_View' },
  DUPLICATE: { label: 'Duplicate', icon: ICONS.copy.name, automationId: 'roleMenuActions_Duplicate' },
  DELETE: { label: 'Delete', icon: ICONS.deleteRegular.name, automationId: 'roleMenuActions_Delete' },
};

export const getColumns = (columns, isMsp = undefined) => {
  if (!columns) {
    return null;
  }
  let tmpColumns = Object.values(columns);
  if (tmpColumns.find((c) => c.columnName === 'customer') && isMsp === false) {
    tmpColumns = columns.filter((c) => c.columnName !== 'customer');
  }
  return Object.values(tmpColumns).map((c) => ({
    name: c.columnName,
    title: c.title,
    getCellValue: c.getCellValue,
  }));
};

export const getSortingColumns = (columns) =>
  Object.values(columns).map((c) => ({ columnName: c.columnName, sortingEnabled: c.sortingEnabled }));

export const getDefaultSorting = (columns) =>
  Object.values(columns)
    .filter((c) => c.direction)
    .map((c) => ({
      columnName: c.columnName,
      direction: c.direction,
    }));

export const EDIT_USER_MODAL_FIELDS = {
  USER_NAME: { id: 'userName', label: 'User Name', placeholder: 'User Name' },
  FIRST_NAME: { id: 'firstName', label: 'First Name', placeholder: 'First Name' },
  LAST_NAME: { id: 'lastName', label: 'Last Name', placeholder: 'Last Name' },
};

export const GET_USERS_QUERY_PARAMS = {
  SEARCH: { name: 'search', defaultValue: '' },
  PAGINATION_TOKEN: { name: 'paginationToken', defaultValue: null },
  PAGE_SIZE: { name: 'pageSize', defaultValue: DEFAULT_PAGE_SIZE },
  USER_NAME: { name: 'username', defaultValue: '' },
  FIRST_NAME: { name: 'firstName', defaultValue: '' },
  LAST_NAME: { name: 'lastName', defaultValue: '' },
  ROLE: { name: 'role', defaultValue: '' },
  CREATED_AT: { name: 'createdAt', defaultValue: '' },
  CREATED_AT_FILTER_TYPE: { name: 'createdAtFilterType', defaultValue: '' },
  LAST_LOGIN: { name: 'lastLogin', defaultValue: '' },
  LAST_LOGIN_FILTER_TYPE: { name: 'lastLoginFilterType', defaultValue: '' },
  ENABLED: { name: 'enabled', defaultValue: '' },
};

export const doesUserMatchSearch = (user, search) =>
  (user?.username && user.username.indexOf(search) !== -1) ||
  (user?.firstName && user.firstName.indexOf(search) !== -1) ||
  (user?.lastName && user.lastName.indexOf(search) !== -1);
