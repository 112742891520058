import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  ICONS,
  UserSettings,
  UserSettingsContext,
  ListItem,
  ListContent,
} from '@pileus-cloud/anodot-frontend-common/dist';
import UserPicker from 'app/containers/Layout/topNavbar/components/UserPicker';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { Routes } from 'shared/constants/routes';

const UserAccountSettings = (props) => {
  const { usersStore } = useRootStore();
  const { handleLogout, history, classes, currDisplayedAccount } = props;

  const handleMainUserClick = async () => {
    const { authUserKey } = usersStore;
    await usersStore.handleDisplayedUserChange(authUserKey);
    usersStore.rootStore.fetchData(authUserKey);
    history.push(Routes.LANDING);
  };

  const accountClicked = () => {
    history.push(Routes.ACCOUNT);
  };
  const logoutClicked = async () => {
    await handleLogout();
  };

  // eslint-disable-next-line react/prop-types
  function UserSettingListItem({ value, iconName, isActive, onSelect }) {
    const { toggleOpen } = useContext(UserSettingsContext);
    const handleOnSelect = () => {
      onSelect();
      toggleOpen();
    };
    return <ListItem value={value} iconName={iconName} onSelect={handleOnSelect} isActive={isActive} />;
  }

  return (
    <UserSettings tooltip={usersStore.getCurrUserName} value={usersStore.getCurrUserName} id="userSettings">
      <ListContent>
        {currDisplayedAccount && (
          <>
            <UserSettingListItem value="Account" onSelect={accountClicked} iconName={ICONS.gear.name} />
            <UserPicker store={usersStore} history={history} classes={classes} />
            <UserSettingListItem
              value="Main user"
              onSelect={handleMainUserClick}
              iconName={ICONS.userSecretSlash.name}
            />
          </>
        )}
        <UserSettingListItem value="Log out" onSelect={logoutClicked} iconName={ICONS.arrowRightFromBracket.name} />
      </ListContent>
    </UserSettings>
  );
};

UserAccountSettings.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  currDisplayedAccount: PropTypes.object,
};

UserAccountSettings.defaultProps = {
  currDisplayedAccount: null,
};

export default UserAccountSettings;
