import React, { useEffect, useState } from 'react';
import useTable from 'shared/hooks/customHooks/useTable';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import Spinner from 'shared/components/andtComponents/Spinner';
import RoleSubRolesTabHeader from './RoleSubRolesTabHeader';
import RoleSubRolesTabTable from './RoleSubRolesTabTable';

import styles from './RoleTab.module.scss';

const RoleSubRolesTab = ({ row }) => {
  const [subRoles, setSubRoles] = useState([]);
  const [selectedSubRoles, setSelectedSubRoles] = useState([]);
  const [search, setSearch] = useState('');
  const [isInProgress, setIsInProgress] = useState(false);

  const { NewTableWrapper } = useTable();

  const { fetchRoleByInternalName, removeSubRoles } = useRoles();

  const { data, isLoading } = fetchRoleByInternalName(row?.identifier?.internalName);

  useEffect(() => {
    if (data?.subRoles) {
      setSubRoles(data.subRoles);
    }
  }, [data?.subRoles]);

  useEffect(() => {
    if (data?.subRoles) {
      const searchedSubRoles = data.subRoles.filter((role) => role.displayName.indexOf(search) !== -1) || [];
      setSubRoles(searchedSubRoles);
    }
  }, [data?.subRoles, search]);

  const removeSubRolesClicked = async (subRolesToRemove) => {
    setIsInProgress(true);
    await removeSubRoles.mutateAsync({
      roleInternalName: row?.identifier?.internalName,
      subRolesInternalNames: subRolesToRemove.map((u) => u?.internalName),
    });
    setIsInProgress(false);
  };

  const removeSubRolesListClicked = async () => {
    const subRolesToRemove = subRoles.filter((_, index) => selectedSubRoles.includes(index));
    return removeSubRolesClicked(subRolesToRemove);
  };

  return (
    <>
      {isLoading || isInProgress ? (
        <div className="position-relative top-50">
          <Spinner />
        </div>
      ) : (
        <div className={styles.usersTableContainer}>
          <NewTableWrapper className="sub-table" isCompact>
            <RoleSubRolesTabHeader
              removeSubRoleClicked={removeSubRolesListClicked}
              subRolesCount={subRoles?.length}
              selectedSubRoles={selectedSubRoles}
              search={search}
              setSearch={setSearch}
              role={data}
            />
            <RoleSubRolesTabTable
              removeSubRoleClicked={(role) => removeSubRolesClicked([role])}
              subRoles={subRoles}
              selectedSubRoles={selectedSubRoles}
              setSelectedSubRoles={setSelectedSubRoles}
            />
          </NewTableWrapper>
        </div>
      )}
    </>
  );
};

export default RoleSubRolesTab;
