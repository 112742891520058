import React from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import Input from 'shared/components/andtComponents/Input';
import Button from 'shared/components/andtComponents/Button';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import Pagination from 'shared/components/andtComponents/Pagination';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import { MAX_EXPORTED_ROWS } from 'shared/constants/appConstants';
import SimpleSelect from 'shared/components/andtComponents/SimpleSelect';
import commonStyles from 'app/containers/Onboarding/AwsOnboarding/pages/Pages.module.scss';
import classes from './billingHistoryTableHeader.module.scss';

const InputWithNoTypes = Input as React.FC<any>;

type Props = {
  rowsCount: number;
  searchText?: string | null;
  setSearchText(txt: string): void;
  isExpanded: boolean;
  setIsExpanded(d: boolean): void;
  onPageChange(newIndex: number): void;
  currentPage: number;
  totalPages: number;
  headerText?: string;
  onDateChange(s: Date, e: Date): void;
  startDate: Date;
  endDate: Date;
};
const TableFilters: React.FC<Props> = ({
  rowsCount,
  searchText,
  setSearchText,
  isExpanded,
  setIsExpanded,
  onPageChange,
  currentPage,
  totalPages,
  headerText = '',
  onDateChange,
  startDate,
  endDate,
}) => (
  <div className={classes.header}>
    <div>
      <Button
        automationId="expand-rows"
        text=""
        isSecondary
        icon={() => <GenerateIcon iconName={isExpanded ? ICONS.collapse.name : ICONS.expand.name} />}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      />
      <span className={classes.headerText}>{headerText}</span>
    </div>

    <div className={classes.rightMenu}>
      <DatePickerFilter
        isDateRangeError={false}
        onDateChange={({ startDate, endDate }) => {
          onDateChange?.(startDate, endDate);
        }}
        startDate={startDate}
        endDate={endDate}
        isMaxDateNeeded={false}
        currPeriodGranLevel="month"
        dateFormat="MMM YYYY"
        andtLook
      />
      <div className="position-relative d-flex align-items-center">
        <InputWithNoTypes
          className={classes.input}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search"
          name="search-recommendations"
          isSearch
          searchComponent={<GenerateIcon iconName={ICONS.search.name} />}
          type="text"
        />
      </div>
      <Tooltip
        title={
          rowsCount > MAX_EXPORTED_ROWS
            ? `You can export up to ${MAX_EXPORTED_ROWS} records, please refine your filters to enable export`
            : 'Export'
        }
        arrow
      >
        <SimpleSelect
          className={commonStyles.select}
          value="default"
          options={[
            {
              label: (
                <span>
                  <GenerateIcon iconName={ICONS.download.name} /> Table Summary
                </span>
              ),
              value: 'default',
            },
            {
              label: (
                <span>
                  <GenerateIcon iconName={ICONS.download.name} /> CSV
                </span>
              ),
              value: 'csv',
            },
            {
              label: (
                <span>
                  <GenerateIcon iconName={ICONS.download.name} /> PDF
                </span>
              ),
              value: 'pdf',
            },
          ]}
          onChange={() => 'TODO'}
        />
      </Tooltip>
      <Pagination currPageIdx={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
    </div>
  </div>
);

export default TableFilters;
