import React, { useEffect, useState } from 'react';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@pileus-cloud/anodot-frontend-common/dist';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import FieldSidebarFilter from 'shared/components/FilterSidebar/FieldSidebarFilter';
import { SwitchButtonsContainer } from 'recommendationsNew/components/shared/switchButtonsContainer';
import {
  DROPDOWN_ADDITIONAL_FILTERS,
  FILTERS,
  getStarredSwitchButtons,
  SWITCH_BUTTON_STATUSES,
} from 'recommendationsNew/consts';
import useDynamicFilter from 'recommendationsNew/hooks/react-query/useDynamicFilter';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import { FilterTypes } from 'usage/constants/usageConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { getLinkedAccountName } from 'shared/utils/cloudUtils';
import { cloneDeep } from 'lodash';
import { SearchFilters } from './searchFilters';

import classes from './additionalFilters.module.scss';

export function AdditionalFilters() {
  const [isOpen, setIsOpen] = useState(false);
  const { usersStore } = useRootStore();
  const { currDispUserCloudAccountType } = usersStore;
  const additionalDropDownFilters = Object.values(DROPDOWN_ADDITIONAL_FILTERS)
    .sort((a, b) => a.order - b.order)
    .map((filter) =>
      filter.id === DROPDOWN_ADDITIONAL_FILTERS.LINKED_ACCOUNT_ID.id
        ? { ...filter, label: getLinkedAccountName(currDispUserCloudAccountType) }
        : filter,
    );
  const [displayedFilters, setDisplayedFilters] = useState(additionalDropDownFilters);
  const [filtersSearchString, setFiltersSearchString] = useState('');

  const [currentlyOpenFilter, setCurrentlyOpenFilter] = useState(undefined);
  const [currentlyOpenFilterSelectedValues, setCurrentlyOpenFilterSelectedValues] = useState(undefined);

  const { recommendationFilters: filtersContext, setRecommendationFilters: setFiltersContext } =
    useRecommendationsNewContext();

  const [localFiltersCopy, setLocalFiltersCopy] = useState(cloneDeep(filtersContext));
  const [excludedAdditionalFilters, setExcludedAdditionalFilters] = useState(filtersContext?.excludedAdditionalFilters);
  const [subOptionsFiltersCopy, setSubOptionsFiltersCopy] = useState({});

  useEffect(() => {
    setExcludedAdditionalFilters(filtersContext?.excludedAdditionalFilters);
  }, [filtersContext?.excludedAdditionalFilters]);

  const onFiltersSearchStringChange = (searchString) => {
    setFiltersSearchString(searchString);
    const searchedFilters = additionalDropDownFilters?.filter((option) =>
      option.label.toLowerCase().includes(searchString.toLowerCase()),
    );
    setDisplayedFilters(searchedFilters);
  };

  const onFilterValuesChange = (fieldName, selection, subMenu) => {
    if (currentlyOpenFilter === fieldName) {
      // This is the case when drop down values are being selected
      setCurrentlyOpenFilterSelectedValues(selection);
    } else {
      // This is relevant for the cases of ClearAll or 'x' button clicked
      setLocalFiltersCopy({
        ...localFiltersCopy,
        [fieldName]: selection,
      });
    }
    if (subMenu) {
      setSubOptionsFiltersCopy({
        ...subOptionsFiltersCopy,
        [fieldName]: selection,
      });
    }
  };

  const onFilterTypeChange = (filterId, subField) => {
    if (!filterId) {
      return;
    }
    let updatedFilters;
    if (excludedAdditionalFilters?.[filterId]) {
      updatedFilters = { ...excludedAdditionalFilters };
      delete updatedFilters[filterId];
      if (Object.keys(updatedFilters)?.length === 0) {
        updatedFilters = undefined;
      }
    } else {
      const excludeValue = subField ? { [subField]: FilterTypes.EXCLUDE } : FilterTypes.EXCLUDE;
      updatedFilters = { ...(excludedAdditionalFilters || {}), [filterId]: excludeValue };
    }
    setExcludedAdditionalFilters(updatedFilters);
  };

  const onOpenAdditionalFiltersChange = () => {
    const newIsOpenState = !isOpen;
    setIsOpen(newIsOpenState);
    // If additional filters was closed and now opening - initialize local filters copy
    if (newIsOpenState === true) {
      setLocalFiltersCopy(filtersContext);
    } else {
      // If additional filters was opened and now closing - apply filters to context
      let newLocalFilterCopy = { ...localFiltersCopy };
      if (currentlyOpenFilter) {
        newLocalFilterCopy = {
          ...newLocalFilterCopy,
          [currentlyOpenFilter]: currentlyOpenFilterSelectedValues,
        };
        setLocalFiltersCopy(newLocalFilterCopy);
      }
      setFiltersContext({ ...newLocalFilterCopy, excludedAdditionalFilters });
    }
  };

  const openCurrentFilter = (filterId, isSubMenu) => {
    if (!isSubMenu && currentlyOpenFilter === filterId) {
      setLocalFiltersCopy({
        ...localFiltersCopy,
        [filterId]: currentlyOpenFilterSelectedValues,
      });
      setCurrentlyOpenFilter(undefined);
      setCurrentlyOpenFilterSelectedValues(undefined);
    } else {
      setCurrentlyOpenFilter(filterId);
    }
  };

  const onStarredChangeState = (event) => {
    // eslint-disable-next-line no-nested-ternary
    const filterValue = [SWITCH_BUTTON_STATUSES.STARRED].includes(event.option)
      ? true
      : [SWITCH_BUTTON_STATUSES.NOT_STARRED].includes(event.option)
      ? false
      : null;

    setLocalFiltersCopy({
      ...localFiltersCopy,
      [FILTERS.IS_STARRED.id]: filterValue,
    });
  };
  const [tagParams, setTagParams] = useState({});
  const [tagKey, setTagKey] = useState(null);
  const { data: dynamicFilter, isLoading } = useDynamicFilter(currentlyOpenFilter).fetchDynamicTagFilter(
    tagParams,
    tagKey,
  );

  return (
    <div
      className={`${classes.additionalFiltersContainer} ${isOpen ? classes.opened : ''}`}
      automation-id="heatmap-additional-filters"
    >
      <Popover open={isOpen} onOpenChange={onOpenAdditionalFiltersChange}>
        <PopoverTrigger className={classes.popoverTrigger}>
          <Tooltip title="Additional Filters" placement="top">
            <div className={classes.filtersButton}>
              <GenerateIcon className={classes.filtersIcon} iconName={ICONS.barsFilter.name} />
            </div>
          </Tooltip>
        </PopoverTrigger>

        <PopoverContent align="start" sideOffset={7} className={classes.popoverContent}>
          <div className={classes.searchContainer}>
            <SearchFilters searchValue={filtersSearchString} onSearchChange={onFiltersSearchStringChange} />
          </div>
          <div className={classes.filtersContainer}>
            {displayedFilters?.map((filter) => (
              <div className={classes.fieldSidebarFilterContainer}>
                <FieldSidebarFilter
                  excludeMode={
                    filter.subMenu
                      ? excludedAdditionalFilters?.[filter.id]
                      : excludedAdditionalFilters?.[filter.id] === FilterTypes.EXCLUDE
                  }
                  automation-id={`heatmap-${filter.id}-filter`}
                  handleChange={(fieldName, selection) => {
                    onFilterValuesChange(fieldName, selection, filter.subMenu);
                  }}
                  handleChangeFilterType={onFilterTypeChange}
                  label={filter.label}
                  field={filter.id}
                  subMenu={filter.subMenu}
                  isDisabled={filter.isDisabled}
                  filterIcon={filter.icon}
                  selectedOptions={filter.subMenu ? subOptionsFiltersCopy[filter.id] : localFiltersCopy[filter.id]}
                  showKeysOfSubMenuLoading={filter.subMenu}
                  showSubOptionsLoading={filter.subMenu && isLoading}
                  hideSubMenuExclude={filter.subMenu}
                  keys={() =>
                    !filter.subMenu
                      ? null
                      : useDynamicFilter(filter.id === currentlyOpenFilter ? filter.id : undefined).fetchDynamicFilter(
                          { ...localFiltersCopy },
                          (data) => data.page,
                        )
                  }
                  fetchSubOptionsData={(tag, search) => {
                    if (tag && tag !== tagKey) {
                      setTagParams({ ...localFiltersCopy });
                      setTagKey(tag);
                    }
                    if (search) {
                      const newLikeAdditionalFilters = { [filter.id]: { [tag]: [search] } };
                      if (
                        JSON.stringify(tagParams.likeAdditionalFilters) !== JSON.stringify(newLikeAdditionalFilters)
                      ) {
                        setTagParams({
                          ...localFiltersCopy,
                          likeAdditionalFilters: newLikeAdditionalFilters,
                        });
                      }
                    } else if (tagParams.likeAdditionalFilters?.[filter.id]) {
                      setTagParams({ ...localFiltersCopy });
                    }
                    return dynamicFilter ? { data: dynamicFilter } : { data: [] };
                  }}
                  fetchData={(search) =>
                    useDynamicFilter(filter.id === currentlyOpenFilter ? filter.id : undefined).fetchDynamicFilter(
                      { ...localFiltersCopy, likeAdditionalFilters: { [filter.id]: search } },
                      (data) => data.page,
                    )
                  }
                  options={[]}
                  onOpen={() => openCurrentFilter(filter.id, filter.subMenu)}
                  toFormatData={false}
                />
              </div>
            ))}
            <SwitchButtonsContainer
              onFiltersChange={onStarredChangeState}
              filtersSearchString={filtersSearchString}
              threeStateSwitchButtons={getStarredSwitchButtons({ isStarred: localFiltersCopy[FILTERS.IS_STARRED.id] })}
            />
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
