import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import Button from 'shared/components/andtComponents/Button';
import AddUsersToRoleModal from '../roleModals/AddUsersToRoleModal/AddUsersToRoleModal';

import styles from './AddToRoleButton.module.scss';

const AddUsersToRole = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  return (
    <>
      <Button
        automationId="addUsersToRole"
        className={{ button: styles.addUsers }}
        disabled={false}
        icon={() => <GenerateIcon iconName={ICONS.addUser.name} className={styles.addUsersIcon} />}
        onClick={onClick}
        text=""
      />
      <AddUsersToRoleModal isOpen={isOpen} setIsOpen={setIsOpen} role={row} />
    </>
  );
};

AddUsersToRole.propTypes = {
  row: PropTypes.string.isRequired,
};

export default AddUsersToRole;
