import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomModal from 'shared/components/andtComponents/Modal';
import Input from 'shared/components/andtComponents/Input';
import FiltersSidebarContainer from 'shared/components/FilterSidebar/FiltersSidebarContainer';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { FilterTypes } from 'usage/constants/usageConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { OPERATORS_KEYS, PageNames } from 'shared/constants/appConstants';
import { useInvoiceFilters } from 'invoices/contexts/InvoiceFiltersContext';
import { filterFieldsTooltip } from 'usage/containers/CustomDashboard/helpers/customFiltersHelper';
import RadioButton from 'shared/components/andtComponents/RadioButton';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import FieldFilter from 'shared/components/FieldFilter';
import { CLOUD_TYPE_IDS, UsersAccountTypeId } from 'users/constants/usersConstants';
import { propComparator } from 'shared/utils/sortUtil';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import getDisplayNameByDivisionName from 'divisions/divisionsHelpers';
import { selectStyles } from 'shared/constants/colorsConstants';
import moment from 'moment';
import Select from 'react-select';
import { formatExcludeFilterMap } from 'shared/utils/filtersUtils';
import { convertObjToMap } from 'shared/utils/apiUtil';
// eslint-disable-next-line max-len
import { formatFilterOptionValueLabel } from 'usage/containers/CostAndUsageExplorer/helpers/customDashboardHelperMethods';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import useBillingRules from '../hooks/react-query/useBillingRules';
import {
  getFilterStatusTypeMapByCloudType as getFilterTypesMapByCloudType,
  MARGIN_TYPES_IDS,
  marginTypes,
  marginTypesByCloudType,
} from '../billingRulesHelpers';
import styles from './addBillingRuleModal.module.scss';

const CustomInputValue = ({ data, ...props }) => {
  const { label } = data;
  return (
    <div {...props}>
      <div className={styles.selectValueWrapper}>
        <div className={styles.valueLabel}>{label}</div>
      </div>
    </div>
  );
};
const formatOptionLabel = ({ label, description }) => (
  <div className={styles.selectOptionsWrapper}>
    <div className={styles.optionLabel}>{label}</div>
    <div className={styles.optionSubLabel}>{description}</div>
  </div>
);

const AddBillingRuleModal = ({ onClose, billingRuleToEdit }) => {
  const { getPageFilters, filtersValuesMap } = useInvoiceFilters();
  const { usersStore } = useRootStore();
  const [billingRule, setBillingRule] = useState({ ...billingRuleToEdit });
  const [isCustomersRule, setIsCustomersRule] = useState(true);
  const [isOneTimeRule, setIsOneTimeRule] = useState(
    billingRuleToEdit?.scope.startMonth === billingRuleToEdit?.scope.endMonth,
  );

  const { createBillingRule, updateBillingRule } = useBillingRules();
  const { mutateAsync: handleCreateBillingRule, isLoading: isCreatingBillingRule } = createBillingRule();
  const { mutateAsync: handleUpdateBillingRule, isLoading: isUpdatingBillingRule } = updateBillingRule();

  const [likeFiltersStatus, setLikeFiltersStatus] = useState({});

  const [filterTypesMap, setFilterTypesMap] = useState(
    getFilterTypesMapByCloudType(usersStore.currDispUserCloudAccountType, billingRule?.filters),
  );

  const [filtersConfig, setFiltersConfig] = useState({});
  const getFiltersConfig = () => {
    const conjunctionConfig = {
      set: (key, flag) =>
        setFiltersConfig((prevFiltersConfig) => ({
          ...prevFiltersConfig,
          [key]: {
            ...prevFiltersConfig[key],
            conjunction: flag,
          },
        })),
      get: (key) => filtersConfig[key]?.conjunction,
    };
    return {
      [AwsCommonFields.CUSTOM_TAGS]: {
        showNotAllocated: true,
        conjunctionSelect: true,
        conjunctionConfig,
      },
    };
  };

  const fieldToFieldDistincValuesMap = getPageFilters(
    PageNames.BILLING_RULES_NEW,
    usersStore.currDispUserCloudAccountType,
    [
      {
        name: AwsCommonFields.SERVICE,
        filter: () => !['AWS_SUPPORT', 'REMOVE_AWS_SUPPORT'].includes(billingRule?.margin?.type),
      },
    ],
  );

  const getSelectValues = (values, filterName) => {
    if (!values) {
      return [];
    }
    const selectedValues = values.map((value) => {
      if (typeof value === 'object') {
        return value;
      }
      const fieldValue = fieldToFieldDistincValuesMap
        .get(filterName)
        ?.find(
          (v) =>
            v === value ||
            Object.keys(v).find((key) => (filterName !== 'linkedaccid' || key !== 'accountId') && v[key] === value),
        );
      return fieldValue ? formatFilterOptionValueLabel(fieldValue, filterName) : { value, label: value };
    });
    return selectedValues;
  };

  const getSelectedOptionsMap = (filters) => {
    if (!filters) {
      return new Map();
    }
    const selectedOptionsMap = new Map();
    Object.entries(filters).forEach(([group, groupFilters]) => {
      if (!groupFilters) {
        return;
      }
      if (['includeFilters', 'excludeFilters', 'likeFilters'].includes(group)) {
        Object.entries(groupFilters).forEach(([filterName, values]) => {
          selectedOptionsMap.set(filterName, getSelectValues(values, filterName));
        });
      }
    });
    return selectedOptionsMap;
  };

  const [selectedFiltersMap, setSelectedFiltersMap] = useState(getSelectedOptionsMap(billingRuleToEdit?.filters));

  const getIsMultiAccountsAccUser = () => {
    const currAccountTypeId = usersStore.getCurrAccountTypeId();
    return (
      currAccountTypeId === UsersAccountTypeId.RESELLER_MULTI_ACCOUNT ||
      currAccountTypeId === UsersAccountTypeId.RESELLER_MULTI_CLOUDS_ACCOUNT
    );
  };
  const getScopeListOfValues = (payerAccListOfValues) => {
    if (payerAccListOfValues && payerAccListOfValues.length && billingRule.accountId) {
      return usersStore.getDivisionValuesByPayerAccount(billingRule.accountId);
    }
    return usersStore.getDivisionValues();
  };

  const prepareLinkedAccountOptions = () => {
    const isMultiAccountsAccUser = getIsMultiAccountsAccUser();
    const allLinkedAccounts = filtersValuesMap?.get(AwsCommonFields.LINKED_ACCOUNT_NAME);
    if (isMultiAccountsAccUser) {
      return allLinkedAccounts.filter((linkedAcc) => linkedAcc.accountId === billingRule.accountId);
    }
    const customerLinkedAccountIds = usersStore.usersModel.divisionAllocatedLinkedAccounts;
    const linkedAccountOptions =
      allLinkedAccounts &&
      allLinkedAccounts.filter((linkA) => customerLinkedAccountIds.includes(linkA.linkedAccountId));
    return linkedAccountOptions;
  };

  const createLinkedAccountOptions = (linkedAccounts) => {
    if (!linkedAccounts) {
      return [];
    }
    const values = linkedAccounts.map((item) => ({ value: item.linkedAccountId, label: item.displayLabel }));
    if (values.length) {
      values.sort(propComparator('label'));
    }
    return values;
  };

  const createScopeOptions = (scopeValue) => {
    if (scopeValue) {
      return [
        {
          value: scopeValue,
          label: LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', scopeValue),
        },
      ];
    }
    const values = getScopeListOfValues(filtersValuesMap.get(AwsCommonFields.PAYER_ACCOUNT)).map((item) => ({
      value: item,
      label: LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', item),
    }));
    if (values.length) {
      values.sort(propComparator('label'));
    }
    return values;
  };

  const handleChangeFilterType = (field, subField, type) => {
    if (!subField) {
      const filterType =
        type !== OPERATORS_KEYS.LIKE && filterTypesMap.get(field) === FilterTypes.INCLUDE
          ? FilterTypes.EXCLUDE
          : FilterTypes.INCLUDE;
      setFilterTypesMap(new Map([...filterTypesMap, [field, filterType]]));
    } else if (subField) {
      setFilterTypesMap(new Map([...formatExcludeFilterMap(convertObjToMap(filterTypesMap), field, subField)]));
    }
    setLikeFiltersStatus(
      type === OPERATORS_KEYS.LIKE
        ? {
            ...likeFiltersStatus,
            [field]: +!likeFiltersStatus[field],
          }
        : {},
    );
  };

  const saveBillingRuleFilters = () => {
    const includeFilters = {};
    const excludeFilters = {};
    const likeFilters = {};
    const caseSensitive = {};
    const conjunction = {};
    selectedFiltersMap.forEach((values, filterName) => {
      const filterType = filterTypesMap.get(filterName);
      if (typeof filterType === 'object') {
        includeFilters[filterName] = [];
        excludeFilters[filterName] = [];
        values.forEach((subValue) => {
          const parentField = subValue.value.split(': ')[0];
          if (!filterType[parentField] || filterType[parentField] === FilterTypes.INCLUDE) {
            includeFilters[filterName] = [...includeFilters[filterName], subValue.value];
          } else {
            excludeFilters[filterName] = [...excludeFilters[filterName], subValue.value];
          }
        });
      } else if (filterType === FilterTypes.INCLUDE) {
        if (likeFiltersStatus[filterName]) {
          likeFilters[filterName] = [...values.map((v) => v.value)];
          if (likeFiltersStatus[filterName]?.caseSensitive) {
            caseSensitive[filterName] = 1;
          }
        } else {
          includeFilters[filterName] = [...values.map((v) => v.value)];
        }
      } else {
        excludeFilters[filterName] = [...values.map((v) => v.value)];
      }
    });
    Object.keys(filtersConfig).forEach((field) => {
      if (filtersConfig[field].conjunction) {
        conjunction[field] = filtersConfig[field].conjunction;
      }
    });
    setBillingRule({
      ...billingRule,
      filters: { ...billingRule.filters, includeFilters, excludeFilters, likeFilters, caseSensitive, conjunction },
    });
  };

  useEffect(() => {
    saveBillingRuleFilters();
  }, [selectedFiltersMap, filterTypesMap, likeFiltersStatus, filtersConfig]);

  return (
    <CustomModal
      open
      title={`${billingRule?.ruleId ? 'Edit' : 'Create'} Billing Rule`}
      headerMode="add"
      onClose={onClose}
      saveDisabled={false}
      saveTitle={billingRule?.ruleId ? 'Update' : 'Create'}
      onSave={() => {
        if (billingRule?.ruleId) {
          handleUpdateBillingRule(billingRule);
        } else {
          billingRule.scope.level = 'customer'; // partner will be supported in the future
          handleCreateBillingRule(billingRule);
        }
      }}
      isLoading={isCreatingBillingRule || isUpdatingBillingRule}
      className={styles.addBillingRuleModal}
      footerBorde
    >
      <div className={styles.content}>
        <div className={styles.billingRuleSettingsContainer}>
          <h4>Billing Rule Settings</h4>
          <label htmlFor="billing-rule-name">
            Billing Rule Name
            <Input
              value={billingRule?.description?.ruleName}
              onChange={(e) =>
                setBillingRule({
                  ...billingRule,
                  description: { ...billingRule.description, ruleName: e.target.value },
                })
              }
              id="billing-rule-name"
            />
          </label>
          <label htmlFor="billing-rule-description">
            Description
            <Input
              value={billingRule?.description?.ruleDescription}
              onChange={(e) =>
                setBillingRule({
                  ...billingRule,
                  description: { ...billingRule.description, ruleDescription: e.target.value },
                })
              }
              id="billing-rule-description"
            />
          </label>
          <label className={styles.scopeContainer} htmlFor="billing-rule-scope">
            Scope:
            <RadioButton
              onClick={() => setIsCustomersRule(true)}
              value="customers"
              label="Customers"
              primary
              checked={isCustomersRule}
            />
            <RadioButton
              onClick={() => setIsCustomersRule(false)}
              value="linkedAccounts"
              label="Linked Accounts"
              primary
              checked={!isCustomersRule}
            />
          </label>
          <label htmlFor="billing-rule-customers">
            {isCustomersRule ? 'Customers' : 'Linked Accounts'}
            {isCustomersRule ? (
              <FieldFilter
                type="divNames"
                placeHolder="All"
                value={
                  Array.isArray(billingRule?.scope?.customerNames)
                    ? billingRule.scope.customerNames.map((value) => ({
                        value,
                        label: getDisplayNameByDivisionName(value) || value,
                      }))
                    : billingRule?.scope?.customerNames
                }
                options={createScopeOptions()}
                handleChange={(type, value) => {
                  setBillingRule({
                    ...billingRule,
                    scope: { ...billingRule.scope, customerNames: value?.map((v) => v?.value) || [] },
                  });
                }}
                styles={selectStyles}
                id="billing-rule-linked-accounts"
              />
            ) : (
              <FieldFilter
                type="linkedAccounts"
                placeHolder="All"
                value={billingRule?.scope?.linkedAccountIds?.map((value) => ({
                  value,
                  label:
                    createLinkedAccountOptions(prepareLinkedAccountOptions()).find(
                      (laOption) => laOption.value === value,
                    )?.label || value,
                }))}
                options={createLinkedAccountOptions(prepareLinkedAccountOptions())}
                handleChange={(type, value) => {
                  setBillingRule({
                    ...billingRule,
                    scope: { ...billingRule.scope, linkedAccountIds: value?.map((v) => v.value || v) || [] },
                  });
                }}
                styles={selectStyles}
                id="billing-rule-customers"
              />
            )}
          </label>
          <label className={styles.frequencyContainer} htmlFor="billing-rule-start-month">
            Frequency:
            <RadioButton
              onClick={() => setIsOneTimeRule(true)}
              value="one-time"
              label="One Time"
              primary
              checked={isOneTimeRule}
            />
            <RadioButton
              onClick={() => setIsOneTimeRule(false)}
              value="recurring"
              label="Recurring"
              primary
              checked={!isOneTimeRule}
            />
          </label>
          <label htmlFor="billing-rule-start-month">
            {isOneTimeRule ? (
              'Effective Month'
            ) : (
              <>
                Start Month<span className={styles.endMonthLabel}>End Month</span>
              </>
            )}
            <DatePickerFilter
              startDate={billingRule?.scope?.startMonth}
              endDate={billingRule?.scope?.endMonth}
              selectsStart
              isMaxDateNeeded={false}
              andtLook
              currPeriodGranLevel="month"
              isEndDisable={isOneTimeRule}
              dateFormat="MMM YYYY"
              onDateChange={({ startDate, endDate }) => {
                setBillingRule({
                  ...billingRule,
                  scope: {
                    ...billingRule.scope,
                    startMonth: moment(startDate).format('YYYY-MM'),
                    endMonth: isOneTimeRule ? moment(startDate).format('YYYY-MM') : moment(endDate).format('YYYY-MM'),
                  },
                });
              }}
            />
          </label>
          <label htmlFor="billing-rule-margin-type">
            Margin Type
            <Select
              value={marginTypes.find((type) => type.value === billingRule?.margin?.type)}
              components={{ SingleValue: CustomInputValue }}
              formatOptionLabel={formatOptionLabel}
              options={marginTypesByCloudType[usersStore.currDispUserCloudAccountType]}
              menuPosition="fixed"
              menuPlacement="top"
              placeholder="Select"
              styles={selectStyles}
              maxMenuHeight={500}
              onChange={(selectedOption) => {
                setBillingRule({
                  ...billingRule,
                  margin: { ...billingRule.margin, type: selectedOption.value },
                });
              }}
            />
          </label>
          {[
            MARGIN_TYPES_IDS.UPDATE_COST_WITH_FACTOR,
            MARGIN_TYPES_IDS.ADD_FIXED_COST,
            MARGIN_TYPES_IDS.SET_FIXED_RATE,
            MARGIN_TYPES_IDS.ADD_COST_WITH_FACTOR,
          ].includes(billingRule?.margin?.type) && (
            <label htmlFor="billing-rule-margin-amount">
              Amount (
              {[MARGIN_TYPES_IDS.UPDATE_COST_WITH_FACTOR, MARGIN_TYPES_IDS.ADD_COST_WITH_FACTOR].includes(
                billingRule?.margin?.type,
              )
                ? '%'
                : '$'}
              )
              <Input
                type="number"
                step={1}
                value={
                  ['update_cost_with_factor', 'add_cost_with_factor'].includes(billingRule?.margin?.type)
                    ? billingRule?.margin?.factor && parseFloat((billingRule.margin.factor * 100).toFixed(10))
                    : billingRule?.margin?.amount
                }
                onChange={(e) =>
                  setBillingRule({
                    ...billingRule,
                    margin: ['update_cost_with_factor', 'add_cost_with_factor'].includes(billingRule?.margin?.type)
                      ? { ...billingRule.margin, factor: e.target.value / 100 }
                      : { ...billingRule.margin, amount: e.target.value },
                  })
                }
                id="billing-rule-amount"
              />
            </label>
          )}
          {[MARGIN_TYPES_IDS.ADD_FIXED_COST, MARGIN_TYPES_IDS.ADD_COST_WITH_FACTOR].includes(
            billingRule?.margin?.type,
          ) && (
            <label className={styles.customServiceContainer} htmlFor="billing-rule-margin-custom-service">
              Custom Service Name
              <Tooltip
                arrow
                // eslint-disable-next-line max-len
                title="Note: All customers on a shared account can see the custom service name under the service dropdown (the cost will be visible only to the relevant customer)."
              >
                <span className={styles.infoIcon}>
                  <GenerateIcon iconName={ICONS.circleInfo.name} />
                </span>
              </Tooltip>
              <Input
                value={billingRule.margin.custom_service}
                onChange={(e) =>
                  setBillingRule({
                    ...billingRule,
                    margin: { ...billingRule.margin, custom_service: e.target.value },
                  })
                }
                id="billing-rule-custom-service"
              />
            </label>
          )}
          {[MARGIN_TYPES_IDS.ADD_AWS_SUPPORT, MARGIN_TYPES_IDS.REMOVE_AWS_SUPPORT].includes(
            billingRule?.margin?.type,
          ) && (
            <label htmlFor="billing-rule-margin-plan">
              Plan
              <Select
                value={billingRule.margin.plan && { value: billingRule.margin.plan, label: billingRule.margin.plan }}
                options={[
                  'AWS Support [Business]',
                  'AWS Support [Developer]',
                  'AWS Support [Enterprise]',
                  'AWS Premium Support',
                ].map((value) => ({ value, label: value }))}
                onChange={(selectedOption) =>
                  setBillingRule({
                    ...billingRule,
                    margin: { ...billingRule.margin, plan: selectedOption.value },
                  })
                }
                styles={selectStyles}
                menuPosition="fixed"
                placeholder="Select"
                id="billing-rule-margin-plan"
              />
            </label>
          )}
          {billingRule?.margin?.type === MARGIN_TYPES_IDS.ADD_AWS_SUPPORT && (
            <label className={styles.resolutionContainer} htmlFor="billing-rule-resolution">
              Calculate AWS support tiering based on:
              <RadioButton
                onClick={() =>
                  setBillingRule({ ...billingRule, margin: { ...billingRule.margin, resolution: 'customer' } })
                }
                value="customer"
                label="Total"
                primary
                checked={billingRule?.margin?.resolution === 'customer'}
              />
              <RadioButton
                onClick={() =>
                  setBillingRule({ ...billingRule, margin: { ...billingRule.margin, resolution: 'linked-account' } })
                }
                value="linked-account"
                label="Per Linked Account"
                primary
                checked={billingRule?.margin?.resolution === 'linked-account'}
              />
            </label>
          )}
        </div>

        <div className={styles.filtersContainer}>
          <h4>Filters</h4>
          {usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS && (
            <label className={styles.checkbox} htmlFor="billing-rule-exclude-aws-marketplace">
              <Checkbox
                isChecked={billingRule?.filters?.excludeAwsMarketplace}
                onChange={() =>
                  setBillingRule({
                    ...billingRule,
                    filters: {
                      ...billingRule.filters,
                      excludeAwsMarketplace: !billingRule.filters.excludeAwsMarketplace,
                    },
                  })
                }
                primary
              />
              Exclude AWS Marketplace
            </label>
          )}
          {['add_aws_support'].includes(billingRule?.margin?.type) &&
            usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS && (
              <label className={styles.checkbox} htmlFor="billing-rule-exclude-aws-marketplace">
                <Checkbox
                  isChecked={billingRule?.margin.disableMinimumFee}
                  onChange={() =>
                    setBillingRule({
                      ...billingRule,
                      margin: {
                        ...billingRule.margin,
                        disableMinimumFee: !billingRule.margin.disableMinimumFee,
                      },
                    })
                  }
                  primary
                />
                Disable Minimum Fee
              </label>
            )}
          <FiltersSidebarContainer
            isOpen
            selectedOptionsMap={selectedFiltersMap}
            excludedFiltersStatusMap={filterTypesMap}
            fieldToFieldDistincValuesMap={fieldToFieldDistincValuesMap}
            filterTooltipsFields={filterFieldsTooltip()[usersStore.currDispUserCloudAccountType]}
            currDispUserCloudAccountType={usersStore.currDispUserCloudAccountType}
            handleChangeFilterType={handleChangeFilterType}
            handleFilterChange={(filterName, values) =>
              setSelectedFiltersMap(new Map([...selectedFiltersMap, [filterName, values]]))
            }
            handleRemoveFieldFromFiltersValuesMap={(field) => {
              setSelectedFiltersMap(Array.from(selectedFiltersMap).filter((f) => f.field !== field));
            }}
            showTitle={false}
            className={styles.billingRuleFilters}
            likeOperator
            hideSearch
            likeFiltersStatus={likeFiltersStatus}
            likeCaseConfig={{
              get: (field) => likeFiltersStatus[field]?.caseSensitive,
              set: (field, value) => {
                setLikeFiltersStatus({
                  ...likeFiltersStatus,
                  [field]: { ...likeFiltersStatus[field], caseSensitive: +value },
                });
              },
            }}
            filtersConfig={filtersConfig}
            fieldToFieldDistincValuesProps={getFiltersConfig()}
            filtersStatusMap={selectedFiltersMap}
          />
        </div>
      </div>
    </CustomModal>
  );
};

AddBillingRuleModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddBillingRuleModal;
