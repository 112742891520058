import React, { useState } from 'react';
import { Grid, TableColumnResizing, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, IntegratedSelection, SelectionState } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import {
  CustomFormatterWithTooltip,
  CustomHeaderCell,
  CustomSelectionCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { ASSIGN_ROLES_MODAL_COLUMNS, getColumns } from '../../../consts';
import { ReactComponent as NoResults } from '../../img/no-results.svg';

import styles from './AssignRolesModal.module.scss';

export const UserRolesNoData = (userRoles) => {
  if (!userRoles) {
    return null;
  }
  return (
    <div className={styles.noResultsWrapper}>
      <NoResults />
      <span className={styles.mainText}>No Roles for this user</span>
    </div>
  );
};

const AssignRolesModalTable = ({ roles, setSelectedUserRoles }) => {
  const [localColumnsWidth, setLocalColumnsWidth] = useState(
    Object.values(ASSIGN_ROLES_MODAL_COLUMNS).map((c) => ({ columnName: c.columnName, width: c.width })),
  );

  const onSelectionChange = (selected) => {
    const tmp = !selected?.length ? [] : selected.map((index) => roles[index]);
    setSelectedUserRoles(tmp);
  };

  return (
    roles && (
      <div className={styles.assignRolesContainer}>
        <Grid rows={roles} columns={getColumns(ASSIGN_ROLES_MODAL_COLUMNS)} automationId="assign-roles-to-user-list">
          <SelectionState onSelectionChange={onSelectionChange} />
          <IntegratedSelection />
          <DataTypeProvider
            for={[ASSIGN_ROLES_MODAL_COLUMNS.ROLE_NAME.columnName]}
            formatterComponent={(props) => (
              <CustomFormatterWithTooltip overrideStyles={{ fontWeight: 500 }} {...props} />
            )}
          />
          <DataTypeProvider
            for={[ASSIGN_ROLES_MODAL_COLUMNS.ROLE_ID.columnName]}
            formatterComponent={CustomFormatterWithTooltip}
          />
          <TableWrapper virtual noDataCellComponent={() => UserRolesNoData(roles)} height={400} />
          <TableSelection showSelectAll cellComponent={CustomSelectionCell} />
          <TableColumnResizing
            resizingMode="nextColumn"
            columnWidths={localColumnsWidth}
            onColumnWidthsChange={setLocalColumnsWidth}
          />
          <TableHeaderRow cellComponent={CustomHeaderCell} />
        </Grid>
      </div>
    )
  );
};

AssignRolesModalTable.propTypes = {
  roles: PropTypes.array.isRequired,
};

export default AssignRolesModalTable;
