import React, { useEffect, useState } from 'react';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import useTable from 'shared/hooks/customHooks/useTable';
import Spinner from 'shared/components/andtComponents/Spinner';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import { PAGINATION_DIRECTION, USER_ACTIONS } from '../../../consts';
import AssignRolesModalTable from './AssignRolesModalTable';
import AssignRolesModalHeader from './AssignRolesModalHeader';

import styles from './AssignRolesModal.module.scss';

const AssignRolesModal = ({ isOpen, setIsOpen, users }) => {
  const [saveClicked] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [search, setSearch] = useState('');
  const [paginationToken, setPaginationToken] = useState(null);
  const [displayedRoles, setDisplayedRoles] = useState([]);

  const { NewTableWrapper } = useTable();

  const { fetchRoles } = useRoles(isOpen);
  const { data, isLoading } = fetchRoles({ paginationToken, search });

  const { addRolesToUsers } = useUsers(isOpen);

  useEffect(() => {
    if (data?.roles && users?.length && !isLoading) {
      const tmp = data?.roles?.filter((role) => users.every((u) => u.roles?.every((r) => r.id !== role.identifier.id)));
      const searchedRoles = tmp.filter((role) => role?.identifier?.displayName?.indexOf(search) !== -1);
      setDisplayedRoles(searchedRoles);
    }
  }, [data, isLoading, users, isOpen]);

  useEffect(() => {
    setSelectedRoles([]);
  }, [data?.roles]);

  const pageChanged = (direction) => {
    if (direction === PAGINATION_DIRECTION.NEXT && data?.paginationToken) {
      setPaginationToken(data?.paginationToken);
    }
  };

  const onSave = async () => {
    await addRolesToUsers.mutateAsync({
      userIds: users?.length ? users?.map((u) => u.id) : undefined,
      roles: selectedRoles?.map((r) => r.identifier),
    });
    setIsOpen(false);
  };

  return (
    <CustomModal
      className={styles}
      onClose={() => {
        setIsOpen(false);
      }}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      headerMode={MODAL_MODES.ADD}
      footerDisabled={isLoading && saveClicked}
      onSave={onSave}
      open={isOpen}
      automationId="assign-role-modal"
      saveTitle="Add"
      saveDisabled={false}
      title={USER_ACTIONS.ASSIGN_ROLES.label}
    >
      <>
        {isLoading ? (
          <Spinner />
        ) : (
          <NewTableWrapper className="sub-table" isCompact>
            <AssignRolesModalHeader
              pageChanged={pageChanged}
              selectedRolesCount={selectedRoles?.length}
              search={search}
              setSearch={setSearch}
              total={data?.total}
            />
            <AssignRolesModalTable roles={displayedRoles} setSelectedUserRoles={setSelectedRoles} />
          </NewTableWrapper>
        )}
      </>
    </CustomModal>
  );
};

export default AssignRolesModal;
