import { useMutation, useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { STALE_TIME } from 'users/containers/Organization/consts';
import {
  addPartialRolePermissions,
  addSubRolesToRole,
  deletePartialRolePermissions,
  deleteRoles,
  fetchPartialRolePermissions,
  fetchRoleByInternalName,
  fetchRoleDataAccess,
  fetchRolePermissions,
  fetchRoles,
  removeSubRolesFromRole,
  setRoleDataAccess,
  setRolePermissions,
} from './apiRoles';
import { handleError } from './helperFunctions';

export default function useRoles(isEnabled) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const queryKey = [apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLES, userAccountKey];

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    fetchRoles: (params) =>
      useQuery([...queryKey, ...(params ? Object.values(params) : [])], () => fetchRoles(params), {
        enabled: isEnabled === undefined ? true : isEnabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    fetchRoleByInternalName: (roleInternalName) =>
      useQuery([...queryKey, roleInternalName], () => fetchRoleByInternalName(roleInternalName), {
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    addSubRoles: useMutation(
      ({ roleInternalName, subRolesInternalNames }) => addSubRolesToRole(roleInternalName, subRolesInternalNames),
      {
        onSuccess: (_, variables) => queryClient.invalidateQueries([...queryKey, variables.roleInternalName]),
        onError: handleError,
      },
    ),
    removeSubRoles: useMutation(
      ({ roleInternalName, subRolesInternalNames }) => removeSubRolesFromRole(roleInternalName, subRolesInternalNames),
      {
        onSuccess: (_, variables) => queryClient.invalidateQueries([...queryKey, variables.roleInternalName]),
        onError: handleError,
      },
    ),
    fetchRoleDataAccess: (params) =>
      useQuery(queryKey, () => fetchRoleDataAccess(params), {
        enabled: isEnabled === undefined ? true : isEnabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    setRoleDataAccess: useMutation(
      ({ roleInternalName, roleDataAccess }) => setRoleDataAccess(roleInternalName, roleDataAccess),
      {
        onSuccess: (_, variables) => queryClient.invalidateQueries([...queryKey, variables.roleInternalName]),
        onError: handleError,
      },
    ),
    fetchRolePermissions: (roleInternalName) =>
      useQuery([...queryKey, roleInternalName], () => fetchRolePermissions(roleInternalName), {
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    setRolePermissions: useMutation(
      ({ roleInternalName, roleCategoryPermissions }) => setRolePermissions(roleInternalName, roleCategoryPermissions),
      {
        onSuccess: (_, variables) => queryClient.invalidateQueries([...queryKey, variables.roleInternalName]),
        onError: handleError,
      },
    ),
    fetchPartialRolePermissions: (roleInternalName, category, action) =>
      useQuery(
        [...queryKey, roleInternalName, category, action],
        () => fetchPartialRolePermissions(roleInternalName, category, action),
        {
          retry: false,
          staleTime: STALE_TIME,
          onError: handleError,
        },
      ),
    addPartialRolePermissions: useMutation(
      ({ roleInternalName, category, action, roleCategoryPermissions }) =>
        addPartialRolePermissions(roleInternalName, category, action, roleCategoryPermissions),
      {
        onSuccess: (_, variables) =>
          queryClient.invalidateQueries([
            ...queryKey,
            variables.roleInternalName,
            variables.category,
            variables.action,
          ]),
        onError: handleError,
      },
    ),
    deletePartialRolePermissions: useMutation(
      ({ roleInternalName, category, action, roleCategoryPermissions }) =>
        deletePartialRolePermissions(roleInternalName, category, action, roleCategoryPermissions),
      {
        onSuccess: (_, variables) =>
          queryClient.invalidateQueries([
            ...queryKey,
            variables.roleInternalName,
            variables.category,
            variables.action,
          ]),
        onError: handleError,
      },
    ),
    deleteRoles: useMutation(({ roleInternalNames }) => deleteRoles(roleInternalNames), {
      onSuccess: () => queryClient.invalidateQueries(queryKey),
      onError: handleError,
    }),
  };
}
