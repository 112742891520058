import React, { useState } from 'react';
import { camelCase, startCase } from 'lodash';
import { Form, Input } from 'reactstrap';
import AnodotReactSelect from 'shared/components/AnodotReactSelect';
import SwitchButton from 'shared/components/andtComponents/Switch';
import {
  PREFERENCES_RADIO_VALUES,
  PREFERENCES_SELECT_VALUES,
  PREFERENCES_SELECTED_KEYS,
  PREFERENCES_SPECIAL_FIELDS,
  PREFERENCES_SPECIAL_REC_TYPE_SPECIAL_FIELDS,
} from 'shared/constants/preferencesConstants';
import classNames from 'classnames';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import styles from './preferences.module.scss';
import { RecommendationPreference } from './types.ts';

type Props = {
  preference: RecommendationPreference;
  disabled?: boolean;
  onFieldChange(key: string, value: string | number | boolean): void;
};

const SinglePreferenceForm: React.FC<Props> = ({ preference, disabled, onFieldChange }) => {
  const { currencySymbol } = useUserSettingsContext();
  const [selectedRadioKey, setSelectedRadioKey] = useState('');
  const {
    preferences: preferencesConfig,
    preferencesTitles,
    preferencesOrder,
    preferencesTooltips,
    preferenceKey,
  } = preference;
  const sortedPreferences = preferencesOrder
    ? Object.entries(preferencesOrder).sort((a, b) => a[1] - b[1])
    : preferencesConfig;

  let sortedKeys = Array.isArray(sortedPreferences)
    ? sortedPreferences.map(([key]) => key)
    : Object.keys(sortedPreferences).sort((a) => (a === 'enabled' ? 1 : -1));
  sortedKeys = sortedKeys.filter((key) => key !== 'isPreferencesHidden' && key !== 'enabled');

  const getPreferenceTitle = (key) => {
    const title = preferencesTitles[key];
    if (title.includes('(currency)')) {
      return title.replace('(currency)', `(${currencySymbol})`);
    }
    return title;
  };
  const renderInputNumber = (key, inputValue, isRadioDisabled) => {
    let fieldSettings = PREFERENCES_SPECIAL_FIELDS[key] || {};
    if (!PREFERENCES_SPECIAL_FIELDS[key]) {
      fieldSettings = PREFERENCES_SPECIAL_REC_TYPE_SPECIAL_FIELDS[preferenceKey]?.[key] || {};
    }
    const value = fieldSettings.isPercent ? inputValue * 100 : inputValue;

    const onInputValueChange = (val) => {
      let formatted = +val;
      if (fieldSettings.isPercent) {
        formatted = +Math.round(Math.min(fieldSettings.max, Number(val))) / 100;
      } else if (fieldSettings.max && formatted > fieldSettings.max) {
        formatted = fieldSettings.max;
      } else if (fieldSettings.smallerThan) {
        const maxPropertyValue = preferencesConfig[fieldSettings.smallerThan];
        if (formatted > +maxPropertyValue) {
          formatted = +maxPropertyValue;
        }
      }
      onFieldChange(key, Number.isNaN(Number(formatted)) ? String(formatted) : Number(formatted));
    };
    return (
      <>
        <Input
          automationId={`pref-form-${key}-input`}
          type="number"
          min={0}
          max={fieldSettings.max || ''}
          step={1}
          disabled={disabled || isRadioDisabled}
          id="key"
          value={value || 0}
          onChange={(e) => {
            onInputValueChange(e.target.value);
          }}
          className={classNames(styles.newInput, isRadioDisabled && styles.isRadioInput)}
          style={{ fontSize: 12, width: 150 }}
          autoComplete="off"
          {...fieldSettings}
        />
      </>
    );
  };
  const renderInput = (key, isDisabled = false) => {
    const selectItems = PREFERENCES_SELECT_VALUES[camelCase(key)];
    if (selectItems) {
      const selectedKeys = PREFERENCES_SELECTED_KEYS[camelCase(key)];
      return (
        <div style={{ minWidth: '150px' }}>
          <AnodotReactSelect
            automationId={`pref-form-${key}-input`}
            className={styles.select}
            disabled={disabled}
            value={preferencesConfig[key]}
            options={selectItems.map((v, index) => ({ label: v, value: selectedKeys?.[index] || v }))}
            onChange={(selectedOption) => {
              onFieldChange(key, selectedOption);
            }}
          />
        </div>
      );
    }
    if (typeof preferencesConfig[key] === 'boolean') {
      return (
        <SwitchButton
          automationId={`pref-form-${key}-input`}
          isChecked={preferencesConfig[key] === true}
          onChange={(checked) => {
            onFieldChange(key, checked);
          }}
          isDisabled={disabled}
          style={{ marginLeft: 16 }}
        />
      );
    }
    return renderInputNumber(key, +preferencesConfig[key], isDisabled);
  };
  const renderRadioPreference = (radioPreference) => (
    <div className={styles.radioWrapper}>
      {radioPreference.map((key) => (
        <div>
          <div className={styles.radioHeader}>
            {preferencesTitles?.[key] ? getPreferenceTitle(key) : startCase(key)}:
            <input
              automation-id={`pref-form-${key}-radio`}
              type="radio"
              id={key}
              value={selectedRadioKey}
              checked={selectedRadioKey === key}
              onChange={() => {
                setSelectedRadioKey(key);
                const unSelectedKey = radioPreference.find((k) => k !== key);
                onFieldChange(unSelectedKey, '');
              }}
              disabled={disabled}
            />
          </div>
          {renderInput(key, selectedRadioKey !== key)}
        </div>
      ))}
    </div>
  );

  return (
    <Form automationId={`pref-form-${preference.uuid}`} className={styles.preferenceForm}>
      {!sortedKeys.length && <span>Fully automated recommendation. Nothing to set.</span>}
      {sortedKeys.map((key) => {
        const radioPreference = PREFERENCES_RADIO_VALUES[preferenceKey];
        if (radioPreference?.includes(key)) {
          const radioKeys = sortedKeys.filter((k) => radioPreference.includes(k));
          if (radioKeys[0] === key) {
            if (!selectedRadioKey) {
              setSelectedRadioKey(key);
            }
            return renderRadioPreference(radioPreference);
          }
          return null;
        }
        return (
          <div className={styles.fieldWrapper}>
            {preferencesTitles?.[key] ? getPreferenceTitle(key) : startCase(key)}:
            {preferencesTooltips?.[key] && (
              <InfoPopover isSimple mode="outline">
                {preferencesTooltips[key]?.info}
              </InfoPopover>
            )}
            {renderInput(key)}
          </div>
        );
      })}
    </Form>
  );
};

export default SinglePreferenceForm;
