import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import Button from 'shared/components/andtComponents/Button';
// import AddLinkedAccountsToRoleModal from '../roleModals/AccountsChooser/AddLinkedAccountsToRoleModal';

import styles from './AddToRoleButton.module.scss';

const AddLinkedAccountsToRole = (/* { row } */) => {
  const [, /* isOpen */ setIsOpen] = useState(false);

  const onClick = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  return (
    <>
      <Button
        automationId="addLinkedAccountsToRole"
        className={{ button: styles.addUsers }}
        disabled={false}
        icon={() => <GenerateIcon iconName={ICONS.addLinkedAccounts.name} className={styles.addUsersIcon} />}
        onClick={onClick}
        text=""
      />
      {/* <AddLinkedAccountsToRoleModal isOpen={isOpen} setIsOpen={setIsOpen} role={row} /> */}
    </>
  );
};

AddLinkedAccountsToRole.propTypes = {
  // row: PropTypes.string.isRequired,
};

export default AddLinkedAccountsToRole;
