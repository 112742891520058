import Ec2VersionUpgrade from './recommendations/aws/ec2VersionUpgrade';
import Ec2StoppedInstance from './recommendations/aws/ec2StoppedInstance';
import RdsClassChange from './recommendations/aws/rdsClassChange';
import RdsReservedInstance from './recommendations/aws/rdsReservedInstance';
import Ec2SavingPlan from './recommendations/aws/ec2SavingPlan';
import RdsIdle from './recommendations/aws/rdsIdle';
import RdsVersionUpgrade from './recommendations/aws/rdsVersionUpgrade';
import DynamoDbIdle from './recommendations/aws/dynamoDbIdle';
import IpUnattached from './recommendations/aws/ipUnattached';
import EbsUnattached from './recommendations/aws/ebsUnattached';
import NatGatewayUtilLow from './recommendations/aws/natGatewayUtilLow';
import RedshiftUtilLow from './recommendations/aws/redshiftUtilLow';
import EbsTypeChange from './recommendations/aws/ebsTypeChange';
import S3StorageClass from './recommendations/aws/s3StorageClass';
import RdsIopsChange from './recommendations/aws/rdsIopsChange';
import KmsOld from './recommendations/aws/kmsOld';
import DocumentDbIdle from './recommendations/aws/documentDbIdle';
import EbsOutdatedSnapshot from './recommendations/aws/ebsOutdatedSnapshot';
import NeptuneUtilLow from './recommendations/aws/neptuneUtilLow';
import Ec2LowCpuUsage from './recommendations/aws/ec2LowCpuUsage';
import S3MultipartUpload from './recommendations/aws/s3MultipartUpload';
import S3Idle from './recommendations/aws/s3Idle';
import CloudtrailDuplicateTrails from './recommendations/aws/cloudtrailDuplicateTrails';
import S3Versioning from './recommendations/aws/s3Versioning';
import Ec2Idle from './recommendations/aws/ec2Idle';
import KmsIdle from './recommendations/aws/kmsIdle';
import ElasticacheUtilLow from './recommendations/aws/elasticacheUtilLow';
import Ec2DataTransfer from './recommendations/aws/ec2DataTransfer';
import AzureVmRightSizing from './recommendations/azure/azureVmRightSizing';
import OpenSearchRI from './recommendations/aws/opensearchRI';
import AzureVmStopped from './recommendations/azure/azureVmStopped';
import AzureVmIdle from './recommendations/azure/azureVmIdle';
import AzureOutdatedSnapshot from './recommendations/azure/azureOutdatedSnapshot';
import AzureDiskUnattached from './recommendations/azure/azureDiskUnattached';
import AzureKustoUnusedData from './recommendations/azure/azureKustoUnusedData';
import AzureSqlIdle from './recommendations/azure/azureSqlIdle';
import AzureDiskTypeChange from './recommendations/azure/azureDiskTypeChange';
import AzureVmReservedInstance from './recommendations/azure/azureVmReservedInstance';
import AzureMySqlReservedCapacity from './recommendations/azure/azureMySqlReservedCapacity';
import AzureSqlDataWarehouseReservedCapacity from './recommendations/azure/azureSqlDataWarehouseReservedCapacity';
import AzureRedisReservedCapacity from './recommendations/azure/azureRedisReservedCapacity';
import AzurePostgresqlReservedCapacity from './recommendations/azure/azurePostgresqlReservedCapacity';
import AzureCosmosDbReservedCapacity from './recommendations/azure/azureCosmosDbReservedCapacity';
import AzureAppServiceReservedCapacity from './recommendations/azure/azureAppServiceReservedCapacity';
import AzureDataExplorerReservedCapacity from './recommendations/azure/azureDataExplorerReservedCapacity';
import AzureSqlReservedCapacity from './recommendations/azure/azureSqlReservedCapacity';
import GcpVmStopped from './recommendations/gcp/gcpVmStopped';
import GcpIpIdle from './recommendations/gcp/gcpIpIdle';
import GcpDiskUnattached from './recommendations/gcp/gcpDiskUnattached';
import GcpVmIdle from './recommendations/gcp/gcpVmIdle';
import GcpUsageCommitment from './recommendations/gcp/gcpUsageCommitment';
import GcpVmRightsizing from './recommendations/gcp/gcpVmRightsizing';
import BaseRecommendation from './recommendations/baseRecommendation';
import EsUtilLow from './recommendations/aws/esUtilLow';
import IdleLoadBalancer from './recommendations/aws/idleLoadBalancer';
import AzureIdleLoadBalancer from './recommendations/azure/azureIdleLoadBalancer';
import AzureSnapshotMigration from './recommendations/azure/azureSnapshotMigration';
import AzureCosmosDbRightSizing from './recommendations/azure/azureCosmosDbRightSizing';
import EcsFargate from './recommendations/aws/ecsFargate';
import IpMigrateV4ToV6 from './recommendations/aws/ipMigrateV4ToV6';
import RedshiftReservedInstance from './recommendations/aws/redshiftReservedInstance';
import ElasticacheReservedInstance from './recommendations/aws/elasticacheReservedInstance';
import K8sWorkloadRightsizing from './recommendations/k8s/k8sWorkloadRightsizing';
import EksExtendedSupport from './recommendations/aws/eksExtendedSupport';
import RdsExtendedSupport from './recommendations/aws/RdsExtendedSupport';
import RdsAuroraIops from './recommendations/aws/rdsAuroraIops';

export const RECOMMENDATION_TYPES_MAPPING = {
  DEFAULT: {
    component: BaseRecommendation,
  },
  EC2_VERSION_UPGRADE: {
    typeId: 'version-upgrade',
    typeName: 'EC2 Generation Upgrade',
    component: Ec2VersionUpgrade,
  },
  RDS_VERSION_UPGRADE: {
    typeId: 'rds-version-upgrade',
    typeName: 'RDS Generation Upgrade',
    component: RdsVersionUpgrade,
  },
  RDS_CLASS_CHANGE: {
    typeId: 'rds-class-change',
    typeName: 'RDS Right Sizing',
    component: RdsClassChange,
  },
  RDS_IOPS_CHANGE: {
    typeId: 'rds-iops-change',
    typeName: 'RDS Provisioned IOPS',
    component: RdsIopsChange,
  },
  RDS_RESERVED_INSTANCE: {
    typeId: 'rds-ri',
    typeName: 'RDS Reserved Instance',
    component: RdsReservedInstance,
  },
  RDS_IDLE: {
    typeId: 'rds-idle',
    typeName: 'Idle RDS Instance',
    component: RdsIdle,
  },
  RDS_IOPS: {
    typeId: 'rds-iops-optimized',
    typeName: 'RDS Aurora I/O Optimized Configuration',
    component: RdsAuroraIops,
  },
  DYNAMODB_IDLE: {
    typeId: 'dynamodb-idle',
    typeName: 'Idle Dynamo DB',
    component: DynamoDbIdle,
  },
  IP_UNATTACHED: {
    typeId: 'ip-unattached',
    typeName: 'Unattached IP',
    component: IpUnattached,
  },
  NAT_GATEWAY_UTIL_LOW: {
    typeId: 'nat-gateway-util-low',
    typeName: 'NAT Gateway low utility',
    component: NatGatewayUtilLow,
  },
  REDSHIFT_UTIL_LOW: {
    typeId: 'redshift-util-low',
    typeName: 'Low utilization redshift ',
    component: RedshiftUtilLow,
  },
  EBS_UNATTACHED: {
    typeId: 'ebs-unattached',
    typeName: 'Ebs Unattached',
    component: EbsUnattached,
  },
  EBS_TYPE_CHANGE: {
    typeId: 'ebs-type-change',
    typeName: 'EBS Type Change',
    component: EbsTypeChange,
  },
  EBS_OUTDATED_SNAPSHOT: {
    typeId: 'ebs-outdated-snapshot',
    typeName: 'Outdated EBS Snapshot',
    component: EbsOutdatedSnapshot,
  },
  EC2_STOPPED_INSTANCE: {
    typeId: 'ec2-stopped-instance',
    typeName: 'EC2 Stopped Instance',
    component: Ec2StoppedInstance,
  },
  EC2_SAVINGS_PLANS: {
    typeId: 'ec2-savings-plans',
    typeName: 'Compute Savings Plan',
    component: Ec2SavingPlan,
  },
  EC2_LOW_CPU_USAGE: {
    typeId: 'ec2-low-cpu-usage',
    typeName: 'EC2 Right Sizing',
    component: Ec2LowCpuUsage,
  },
  EC2_IDLE: {
    typeId: 'ec2-idle',
    typeName: 'Idle EC2 Instance',
    component: Ec2Idle,
  },
  KMS_OLD: {
    typeId: 'kms-old',
    typeName: 'Old KMS',
    component: KmsOld,
  },
  KMS_IDLE: {
    typeId: 'kms-idle',
    typeName: 'Disabled KMS',
    component: KmsIdle,
  },
  DOCUMENT_DB_IDLE: {
    typeId: 'documentdb-util-low',
    typeName: 'DocumentDB Idle',
    component: DocumentDbIdle,
  },
  NEPTUNE_UTIL_LOW: {
    typeId: 'neptune-util-low',
    typeName: 'Neptune DB Idle',
    component: NeptuneUtilLow,
  },
  S3_STORAGE_CLASS: {
    typeId: 's3-storage-class',
    typeName: 'S3 Storage Class Change',
    component: S3StorageClass,
  },
  S3_MULTIPART_UPLOAD: {
    typeId: 's3-multipart-upload',
    typeName: 'S3 Multipart upload',
    component: S3MultipartUpload,
  },
  S3_IDLE: {
    typeId: 's3-idle',
    typeName: 'S3 Inactive',
    component: S3Idle,
  },
  S3_VERSIONING: {
    typeId: 's3-versioning',
    typeName: 'S3 Versioning',
    component: S3Versioning,
  },
  CLOUDTRAIL_DUPLICATE_TRAILS: {
    typeId: 'cloudtrail-duplicate-trails',
    typeName: 'Duplicate CloudTrail',
    component: CloudtrailDuplicateTrails,
  },
  EC2_DATA_TRANSFER: {
    typeId: 'ec2-udt',
    typeName: 'EC2 Data Transfer',
    component: Ec2DataTransfer,
  },
  ES_UTIL_LOW: {
    typeId: 'es-util-low',
    typeName: 'Elasticsearch Idle',
    component: EsUtilLow,
  },
  IDLE_LOAD_BALANCER: {
    typeId: 'idle-load-balancer',
    typeName: 'Idle Load Balancer',
    component: IdleLoadBalancer,
  },
  ELASTICACHE_UTIL_LOW_PROPERTIES: {
    typeId: 'elasticache-util-low',
    typeName: 'ElastiCache Idle',
    component: ElasticacheUtilLow,
  },
  ELASTICACHE_RI_PROPERTIES: {
    typeId: 'elasticache-ri',
    typeName: 'ElastiCache Reserved Instance',
    component: ElasticacheReservedInstance,
  },
  OPEN_SEARCH_RI: {
    typeId: 'opensearch-ri',
    typeName: 'OpenSearch RI',
    component: OpenSearchRI,
  },
  AZURE_VM_RIGHT_SIZING: {
    typeId: 'azure-vm-rightsizing',
    typeName: 'Azure VM Right Sizing',
    component: AzureVmRightSizing,
  },
  AZURE_VM_STOPPED: {
    typeId: 'azure-vm-stopped',
    typeName: 'azure vm stopped',
    component: AzureVmStopped,
  },
  AZURE_VM_IDLE: {
    typeId: 'azure-vm-idle',
    typeName: 'azure vm idle',
    component: AzureVmIdle,
  },
  AZURE_OUTDATE_SNAPSHOT: {
    typeId: 'azure-outdated-snapshot',
    typeName: 'azure outdated snapshot',
    component: AzureOutdatedSnapshot,
  },
  AZURE_DISK_UNATTACHED: {
    typeId: 'azure-disk-unattached',
    typeName: 'azure disk unattached',
    component: AzureDiskUnattached,
  },
  AZURE_KUSTO_UNUSED_DATA: {
    typeId: 'azure-kusto-unused-data',
    typeName: 'azure kusto unused data',
    component: AzureKustoUnusedData,
  },
  AZURE_SQL_IDLE: {
    typeId: 'azure-sql-idle',
    typeName: 'azure sql idle',
    component: AzureSqlIdle,
  },
  AZURE_DISK_TYPE_CHANGE: {
    typeId: 'azure-disk-type-change',
    typeName: 'Azure Disk Type Change',
    component: AzureDiskTypeChange,
  },
  AZURE_VM_RESERVED_INSTANCE: {
    typeId: 'azure-vm-ri',
    typeName: 'Azure VM Reserved Instance',
    component: AzureVmReservedInstance,
  },
  AZURE_MYSQL_RESERVED_CAPACITY: {
    typeId: 'azure-mysql-reserved-capacity',
    typeName: 'Azure MySql Reserved Capacity',
    component: AzureMySqlReservedCapacity,
  },
  AZURE_SQL_RESERVED_CAPACITY: {
    typeId: 'azure-sql-reserved-capacity',
    typeName: 'Azure Sql Reserved Capacity',
    component: AzureSqlReservedCapacity,
  },
  AZURE_SQL_DATA_WAREHOUSE_RESERVED_CAPACITY: {
    typeId: 'azure-sql-data-warehouse-reserved-capacity',
    typeName: 'Azure Sql Data Warehouse Reserved Capacity',
    component: AzureSqlDataWarehouseReservedCapacity,
  },
  AZURE_REDIS_RESERVED_CAPACITY: {
    typeId: 'azure-redis-reserved-capacity',
    typeName: 'Azure Redis Reserved Capacity',
    component: AzureRedisReservedCapacity,
  },
  AZURE_DATA_EXPLORER_RESERVED_CAPACITY: {
    typeId: 'azure-data-explorer-reserved-capacity',
    typeName: 'Azure Data Explorer Reserved Capacity',
    component: AzureDataExplorerReservedCapacity,
  },
  AZURE_POSTGRESQL_RESERVED_CAPACITY: {
    typeId: 'azure-postgresql-reserved-capacity',
    typeName: 'Azure PostgreSQL Reserved Capacity',
    component: AzurePostgresqlReservedCapacity,
  },
  AZURE_COSMOS_DB_RESERVED_CAPACITY: {
    typeId: 'azure-cosmos-db-reserved-capacity',
    typeName: 'Azure Cosmos DB Reserved Capacity',
    component: AzureCosmosDbReservedCapacity,
  },
  AZURE_APP_SERVICE_RESERVED_CAPACITY: {
    typeId: 'azure-app-service-reserved-capacity',
    typeName: 'Azure App Service Reserved Capacity',
    component: AzureAppServiceReservedCapacity,
  },
  AZURE_IDLE_LOAD_BALANCER: {
    typeId: 'azure-idle-load-balancer',
    typeName: 'azure idle load balancer',
    component: AzureIdleLoadBalancer,
  },
  AZURE_SNAPSHOT_MIGRATION: {
    typeId: 'azure-snapshot-migration',
    typeName: 'azure snapshot migration',
    component: AzureSnapshotMigration,
  },
  AZURE_COSMOS_DB_RIGHT_SIZING: {
    typeId: 'azure-cosmos-db-right-sizing',
    typeName: 'Cosmos DB Right Sizing',
    component: AzureCosmosDbRightSizing,
  },
  GCP_VM_STOPPED: {
    typeId: 'gcp-vm-stopped',
    typeName: 'gcp vm stopped',
    component: GcpVmStopped,
  },
  GCP_IP_IDLE: {
    typeId: 'gcp-ip-idle',
    typeName: 'gcp ip idle',
    component: GcpIpIdle,
  },
  GCP_DISK_UNATTACHED: {
    typeId: 'gcp-disk-unattached',
    typeName: 'gcp disk unattached',
    component: GcpDiskUnattached,
  },
  GCP_VM_IDLE: {
    typeId: 'gcp-vm-idle',
    typeName: 'gcp vm idle',
    component: GcpVmIdle,
  },
  GCP_VM_RIGHT_SIZING: {
    typeId: 'gcp-vm-rightsizing',
    typeName: 'gcp vm rightsizing',
    component: GcpVmRightsizing,
  },
  GCP_USAGE_COMMITMENT: {
    typeId: 'gcp-usage-commitment',
    typeName: 'gcp usage commitment',
    component: GcpUsageCommitment,
  },
  ECS_FARGATE: {
    typeId: 'ecs-fargate',
    typeName: 'ECS Fargate',
    component: EcsFargate,
  },
  IP_MIGRATE_V4_TO_V6: {
    typeId: 'ip-public-ipv4',
    typeName: 'IP Migrate V4 to V6',
    component: IpMigrateV4ToV6,
  },
  REDSHIFT_RESERVED_INSTANCE: {
    typeId: 'redshift-ri',
    typeName: 'Redshift Reserved Instance',
    component: RedshiftReservedInstance,
  },
  KUBERNETES: {
    typeId: 'k8s-workload-rightsizing',
    typeName: 'K8s Workload Rightsizing',
    component: K8sWorkloadRightsizing,
  },
  EKS_EXTENDED_SUPPORT: {
    typeId: 'eks-extended-support',
    typeName: 'EKS Extended Support',
    component: EksExtendedSupport,
  },
  RDS_EXTENDED_SUPPORT: {
    typeId: 'rds-extended-support',
    typeName: 'RDS Extended Support',
    component: RdsExtendedSupport,
  },
};
