import {
  containsLowerCase,
  containsNumber,
  containsSpecialChar,
  containsUpperCase,
  isEmailValid,
} from 'shared/utils/strUtil';
import {
  MINIMUM_PASSWORD_LENGTH,
  PASSWORD_VALIDATION_RESULT_STRINGS,
  USERNAME_VALIDATION_RESULT_STRINGS,
  UsersType,
} from 'users/constants/usersConstants';

export const isUsernameValid = (username) => {
  let validResult = true;
  const resultStrings = [];

  if (!isEmailValid(username)) {
    validResult = false;
    resultStrings.push(USERNAME_VALIDATION_RESULT_STRINGS.INVALID_USERNAME);
  }

  return {
    validResult,
    resultStrings,
  };
};

export const isPasswordValid = (password) => {
  let validResult = true;
  const resultStrings = [];

  if (!containsSpecialChar(password)) {
    validResult = false;
    resultStrings.push(PASSWORD_VALIDATION_RESULT_STRINGS.INVALID_NO_SPECIAL_CHAR);
  }

  if (!containsLowerCase(password)) {
    validResult = false;
    resultStrings.push(PASSWORD_VALIDATION_RESULT_STRINGS.INVALID_NO_LOWERCASE_CHAR);
  }

  if (!containsUpperCase(password)) {
    validResult = false;
    resultStrings.push(PASSWORD_VALIDATION_RESULT_STRINGS.INVALID_NO_UPPERCASE_CHAR);
  }

  if (password.length <= MINIMUM_PASSWORD_LENGTH) {
    validResult = false;
    resultStrings.push(PASSWORD_VALIDATION_RESULT_STRINGS.INVALID_LENGTH);
  }

  if (!containsNumber(password)) {
    validResult = false;
    resultStrings.push(PASSWORD_VALIDATION_RESULT_STRINGS.INVALID_NO_NUMBER);
  }

  return {
    validResult,
    resultStrings,
  };
};

const hashCode = (s) =>
  s.split('').reduce((a, b) => {
    // eslint-disable-next-line no-param-reassign,no-bitwise
    a = (a << 5) - a + b.charCodeAt(0);
    // eslint-disable-next-line no-bitwise
    return a & a;
  }, 0);
export const getStringHashKeyAndMap = (authToken) => {
  const hashKey = hashCode(authToken);
  const hashMap = new Map([[hashKey, authToken]]);
  return { hashKey, hashMap };
};

export const isUserCustomer = (userType) => [UsersType.RESELLER_CUSTOMER].includes(+userType);
export const isUserCustomerEDP = (userType) =>
  [UsersType.RESELLER_CUSTOMER, UsersType.RESELLER_CUSTOMER_EDP].includes(+userType);
