import React from 'react';
import { Search } from '../../components/Search';
import RemoveButton from '../../components/RemoveButton';

import styles from './RoleTab.module.scss';

const RoleUsersTabHeader = ({ rolesCount, selectedUserRoles, search, setSearch, removeUserClicked }) => (
  <div className={styles.headerContainer}>
    <div className={styles.headerLeft}>
      <div className={styles.header}>
        <div className={styles.title}>
          Roles <span className={styles.count}>({rolesCount})</span>
        </div>
      </div>
    </div>
    <div className={styles.headerRight}>
      {selectedUserRoles?.length > 0 ? (
        <RemoveButton isMultiple selectedCounter={selectedUserRoles?.length} removeClicked={removeUserClicked} />
      ) : (
        <div />
      )}
      <Search search={search} setSearch={setSearch} />
    </div>
  </div>
);

export default RoleUsersTabHeader;
