import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { copyStrToClipboard } from 'shared/utils/strUtil';

import styles from './RoleIdCopy.module.scss';

const RoleIdCopy = ({ roleId }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (e) => {
    e.stopPropagation();
    copyStrToClipboard(roleId);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 300);
  };

  return (
    <div className={classNames(styles.copyIcon, { [styles.copied]: copied })} onClick={copyToClipboard}>
      <GenerateIcon iconName={ICONS.copy.name} />
    </div>
  );
};

RoleIdCopy.propTypes = {
  roleId: PropTypes.string.isRequired,
};

export default RoleIdCopy;
