import { TableHeaderRow, TableRowDetail, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import EditPencil from 'shared/img/icons/PencilIcon';
import { ReactComponent as NoResults } from 'shared/img/icons/no-results.svg';

import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Undo';
import React from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import classes from './CustomersHistory/billingHistory.module.scss';

export const CustomNoDataComponent = () => (
  <div className={classes.noResultsWrapper}>
    <NoResults />
    <span className={classes.mainText}>Nothing was found</span>
    <span className={classes.subText}>Try different filters</span>
  </div>
);

export const CustomHeaderCell = ({ column, ...restProps }) => {
  const { children } = restProps;
  const { statusFilter, name, title } = column;
  if (!column.title) {
    return (
      <TableHeaderRow.Cell {...restProps} column={column}>
        <Tooltip title={title}>
          <span className={classes.headerList} />
        </Tooltip>
      </TableHeaderRow.Cell>
    );
  }
  return (
    <TableHeaderRow.Cell {...restProps} column={column}>
      {name === 'savings' &&
      (statusFilter.isCompleted || statusFilter.isDone) &&
      (statusFilter.isOpen || !statusFilter.isDone) ? (
        <span className={classes.headerList}>
          {children}
          <InfoPopover isSimple>
            Annual Potential/Actual Savings depends on the “Open/Completed” recommendation status.
          </InfoPopover>
        </span>
      ) : (
        <Tooltip title={title}>
          <span className={classes.headerList}>{children}</span>
        </Tooltip>
      )}
    </TableHeaderRow.Cell>
  );
};

export const CustomSelectionCell = (props) => {
  const { row, ...restProps } = props;

  return <TableSelection.Cell {...restProps} row={row} automation-id="select-rec-column" />;
};

// Button components
export const EditButton = ({ onExecute }) => <EditPencil onClick={onExecute} />;
export const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Save changes" size="large">
    <SaveIcon />
  </IconButton>
);
export const CancelButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Cancel changes" size="large">
    <CancelIcon />
  </IconButton>
);
const pt = {
  onExecute: PropTypes.func.isRequired,
};
EditButton.propTypes = pt;
CommitButton.propTypes = pt;
CancelButton.propTypes = pt;

const commandComponents = {
  edit: EditButton,
  commit: CommitButton,
  cancel: CancelButton,
};

export const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id];
  return <CommandButton onExecute={onExecute} />;
};

Command.propTypes = {
  id: PropTypes.string.isRequired,
  onExecute: PropTypes.func.isRequired,
};

export const CSVButton = ({ fetchCSVData }) => (
  <span onClick={fetchCSVData} className="csv-download" style={{ cursor: 'pointer', color: '#80bbf8' }}>
    CSV
  </span>
);

CSVButton.propTypes = {
  fetchCSVData: PropTypes.func.isRequired,
};

export const CustomToggleCell = ({ expanded, ...restProps }) => (
  <TableRowDetail.ToggleCell {...restProps} automation-id="expand-row-icon">
    <div>
      <IconButton>
        <ExpandMoreIcon style={{ transform: expanded ? 'rotate(180deg)' : 'none' }} />
      </IconButton>
    </div>
  </TableRowDetail.ToggleCell>
);

export const CurrencyNumberWithTooltipFormatter = ({ value }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const formattedValue = getCurrencyNumber(value);

  return (
    <Tooltip title={formattedValue}>
      <span>{formattedValue}</span>
    </Tooltip>
  );
};
