import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SelectMulti } from '@pileus-cloud/anodot-frontend-common/dist';
import { uniqBy } from 'lodash';
import moment from 'moment';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import styles from './filters.module.scss';
import { marginTypeToDisplayName } from '../billingRulesHelpers';

const Filters = ({ filters, setFilters, billingRules }) => {
  const ruleNames = useMemo(
    () => billingRules.map((rule) => rule.description.ruleName).map((v) => ({ label: v, value: v })),
    [billingRules],
  );
  const customerNames = useMemo(
    () =>
      uniqBy(
        billingRules
          .map((rule) => rule.scope.customerNames)
          .flat()
          .map((v) => ({ label: v, value: v })),
        'value',
      ),
    [billingRules],
  );
  const frequencies = useMemo(
    () =>
      uniqBy(
        billingRules
          .map((rule) => (rule.scope.startMonth === rule.scope.endMonth ? 'One Time' : 'Recurring'))
          .map((v) => ({ label: v, value: v })),
        'value',
      ),
    [billingRules],
  );
  const marginTypes = useMemo(
    () =>
      uniqBy(
        billingRules.map((rule) => rule.margin.type).map((v) => ({ label: marginTypeToDisplayName[v], value: v })),
        'value',
      ),
    [billingRules],
  );

  return (
    <div className={styles.container}>
      <div>
        <SelectMulti
          label="Rule Name"
          options={ruleNames}
          handleSelectionChange={(vals) => {
            setFilters({ ...filters, ruleNames: vals });
          }}
          selected={filters.ruleNames}
        />
      </div>
      <div>
        <SelectMulti
          label="Customer Name"
          minWidth={250}
          options={customerNames}
          handleSelectionChange={(vals) => {
            setFilters({ ...filters, customerNames: vals });
          }}
          selected={filters.customerNames}
        />
      </div>
      <div>
        <SelectMulti
          label="Frequency"
          options={frequencies}
          handleSelectionChange={(vals) => {
            setFilters({ ...filters, frequencies: vals });
          }}
          selected={filters.frequencies}
        />
      </div>
      <div>
        <SelectMulti
          label="Margin Type"
          minWidth={250}
          options={marginTypes}
          handleSelectionChange={(vals) => {
            setFilters({ ...filters, marginTypes: vals });
          }}
          selected={filters.marginTypes}
        />
      </div>
      <div>
        <DatePickerFilter
          isDateRangeError={false}
          onDateChange={({ startDate, endDate }) => {
            setFilters({
              ...filters,
              startMonth: moment(startDate).format('YYYY-MM'),
              endMonth: moment(endDate).format('YYYY-MM'),
            });
          }}
          startDate={filters.startMonth}
          endDate={filters.endMonth}
          isMaxDateNeeded={false}
          currPeriodGranLevel="month"
          dateFormat="MMM YYYY"
          andtLook
        />
      </div>
    </div>
  );
};

Filters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  billingRules: PropTypes.array.isRequired,
};

export default Filters;
