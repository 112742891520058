import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown, PlusCircle } from 'react-feather';
import { Popover, PopoverContent, PopoverTrigger } from '@pileus-cloud/anodot-frontend-common/dist';
import Tooltip from '@mui/material/Tooltip';
import {
  CLOUD_TYPE_IDS,
  isUsersAccountTypeTenant,
  mapCloudTypeIdToDescription,
  mapUserActionsToAuthorizedUsers,
  USER_ACTIONS,
} from 'users/constants/usersConstants';
import { Routes } from 'shared/constants/routes';
import UserAuthorizationDisplayWrapper from 'shared/components/UserAuthorizationDisplayWrapper';
import TopBarSearch from 'shared/components/TopBarSearch';
import EmptyOptions from 'shared/components/menuList/EmptyOptions';
import MenuItem from 'app/containers/Layout/topNavbar/components/MenuItem';
import MenuCategory from 'app/containers/Layout/topNavbar/components/MenuCategory';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import styles from 'app/containers/Layout/topNavbar/TopNavBar.module.scss';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { cloudTypeToSimpleIcon } from 'shared/constants/appConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import {
  getFilteredAccounts,
  getFilteredCustomers,
  prepareCloudHirarcheyObject,
  prepareCustomerDivisionObject,
} from './accountPickerHelper';

const customStyles = {
  inputStyle: {
    backgroundColor: '#E9F0FF',
    height: '45px',
    border: 'none',
    color: '#8995A0',
    fontWeight: '400',
    borderRadius: 0,
    margin: '0px',
    width: '100%',
    borderBottom: '1px solid #d0dfff',
    padding: '5px 0px 0px 30px',
  },
  iconStyle: { top: '13px', left: '5px' },
  formStyle: { width: '100%' },
};

const AccountPicker = ({
  accountsList,
  currentDisplayedAccount,
  currentDisplayedCloudTypeId,
  currentDisplayedUserType,
  handleAccountChange,
  handleChangeCustomer,
  history,
  classes,
}) => {
  const { usersStore } = useRootStore();
  const [isOpen, setIsOpen] = useState(false);
  const [isCloudProvider, setIsCloudProvider] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const { currDispUserDivisionId, currDispUserAccountKey, currDispUserDivisionName } = usersStore;
  const { updateData } = useUserSettingsContext();

  const customerDivisionsList = usersStore.getAllCustomerDivisionUserAccounts();
  const toggle = () => {
    setIsOpen((prev) => !prev);
  };
  const accountItemClickedHandler = async (accKey, cloudId, accountTypeId = 1, divisionName, divisionId) => {
    handleAccountChange(accKey, cloudId);
    toggle();

    const nextRoutePath = isUsersAccountTypeTenant(accountTypeId) ? Routes.COST_USAGE_EXPLORER : Routes.LANDING;
    if (divisionName && handleChangeCustomer) {
      handleChangeCustomer(divisionId, divisionName, accKey);
    }
    history.push(nextRoutePath, { accountChanged: true });
  };

  window.selectAccount = accountItemClickedHandler;
  window.afterSelectAccount = () => {
    // make sure dropdown is closed
    setIsOpen(false);
  };

  useEffect(() => {
    if (accountsList.length && !customerDivisionsList?.length) {
      setIsCloudProvider(true);
    }
  }, [accountsList]);

  useEffect(() => {
    if (currDispUserAccountKey) {
      const currency = usersStore.getUserCurrencyByAccountDetails(currDispUserAccountKey);
      updateData(currency);
    }
  }, [currDispUserAccountKey]);
  // RENDERS //
  const renderAccounts = (cloudAndAccountsObj, currentCloudName) => {
    if (!Object.keys(cloudAndAccountsObj).length) {
      return <EmptyOptions size="71px" />;
    }
    let arrCloudProviders = Object.keys(cloudAndAccountsObj).map((id) => parseInt(id, 10));
    if (arrCloudProviders.includes(CLOUD_TYPE_IDS.MULTI)) {
      arrCloudProviders = [CLOUD_TYPE_IDS.MULTI, ...arrCloudProviders.filter((s) => s !== CLOUD_TYPE_IDS.MULTI)];
    }
    return (
      <div className={`${styles.listWrapper} show-scroll`}>
        {arrCloudProviders.map((cloudProvId) => {
          const orderedAccountsList = cloudAndAccountsObj[cloudProvId].sort((a) => {
            if (isUsersAccountTypeTenant(a.accountTypeId)) {
              return -1;
            }
            return 0;
          });
          const isIndentationNeeded = orderedAccountsList.some(({ accountTypeId }) =>
            isUsersAccountTypeTenant(accountTypeId),
          );
          const cloudName = mapCloudTypeIdToDescription.get(cloudProvId);
          return (
            <>
              <MenuCategory
                initial={currentCloudName === cloudName || searchInput.length > 0}
                classes={classes}
                key={cloudProvId + searchInput}
                title={cloudName}
                id={`topbar_account_cloud_${cloudProvId}`}
              >
                <div>
                  {orderedAccountsList.map((acc) => (
                    <div key={acc.accountKey} className={classes.itemWrap}>
                      <MenuItem
                        isChild
                        activeName={currentDisplayedAccount}
                        currentDisplayedCloudTypeId={currentDisplayedCloudTypeId}
                        classes={classes}
                        handler={accountItemClickedHandler}
                        item={{
                          key: acc.accountKey,
                          value: acc.accountName || acc.name,
                          cloudId: cloudProvId,
                          accTypeId: acc.accountTypeId,
                          isIndentationNeeded,
                        }}
                        id={`account_id_${acc.accountId}`}
                      />
                    </div>
                  ))}
                </div>
              </MenuCategory>
              <div className={classes.menuDevider} />
            </>
          );
        })}
      </div>
    );
  };

  const renderCustomerAccount = (filteredCustomerObj, currentCloudName) => {
    if (!filteredCustomerObj || !Object.keys(filteredCustomerObj).length) {
      return <EmptyOptions size="71px" />;
    }
    const customerDivisionKeys = Object.keys(filteredCustomerObj).sort();
    return (
      <div className={`${styles.listWrapper} show-scroll`}>
        {customerDivisionKeys.map((customerDivision) => {
          const keys = Object.keys(filteredCustomerObj[customerDivision]);
          return (
            <>
              <MenuCategory
                initial={currDispUserDivisionName === customerDivision || searchInput.length > 0}
                classes={classes}
                key={customerDivision + searchInput}
                title={customerDivision}
                id={`topbar_account_customer_cloud_${customerDivision}`}
              >
                {keys.map((key) => {
                  const cloudName = mapCloudTypeIdToDescription.get(Number(key));
                  return (
                    <div style={{ paddingLeft: '15px' }}>
                      <MenuCategory
                        initial={currentCloudName === cloudName || searchInput.length > 0}
                        classes={classes}
                        key={key + searchInput}
                        title={cloudName}
                        id={`account_type_${key}`}
                      >
                        <div>
                          {Array.isArray(filteredCustomerObj[customerDivision][key]) &&
                            filteredCustomerObj[customerDivision][key].map((acc) => (
                              <div key={acc.accountKey} className={classes.itemWrap}>
                                <MenuItem
                                  isChild
                                  activeName={currDispUserDivisionId === acc.divisionId ? currentDisplayedAccount : ''}
                                  classes={classes}
                                  handler={accountItemClickedHandler}
                                  item={{
                                    key: acc.accountKey,
                                    value: acc.accountName,
                                    cloudId: acc.cloudTypeId,
                                    divisionId: acc.divisionId,
                                    divisionName: customerDivision,
                                  }}
                                  id={`customer_account_id_${acc.accountId}`}
                                />
                              </div>
                            ))}
                        </div>
                      </MenuCategory>
                    </div>
                  );
                })}
              </MenuCategory>
            </>
          );
        })}
      </div>
    );
  };

  const handleSetSearchInput = (e) => {
    const { value } = e.target;
    setSearchInput(value);
  };
  const { cloudAndAccountsObj } = prepareCloudHirarcheyObject(accountsList);
  const { customerList } = prepareCustomerDivisionObject(customerDivisionsList);
  const filteredAccountsObj = getFilteredAccounts(cloudAndAccountsObj, searchInput);
  const filteredCustomerObj = getFilteredCustomers(customerList, searchInput);

  const currentCloudName = mapCloudTypeIdToDescription.get(currentDisplayedCloudTypeId);
  const CloudTypeIcon = cloudTypeToSimpleIcon[currentDisplayedCloudTypeId];
  return (
    <Popover open={isOpen} onOpenChange={toggle}>
      <PopoverTrigger id="topbar_current_displayed_account">
        <Tooltip title="The cloud account you are exploring" arrow>
          <div className={classes.topPickerEnv}>
            <span>
              <CloudTypeIcon className={classes.cloudTypeIcon} />
            </span>
            <span>{currentDisplayedAccount}</span>
            <ChevronDown size={16} />
          </div>
        </Tooltip>
      </PopoverTrigger>
      <div className={classes.popoverWrapper}>
        <PopoverContent className={classes.noPadding}>
          <div className={classes.itemWrap}>
            <TopBarSearch
              inputStyle={customStyles.inputStyle}
              iconStyle={customStyles.iconStyle}
              formStyle={customStyles.formStyle}
              currentValue={searchInput}
              handler={handleSetSearchInput}
              id="topbar_user_search_panel"
              placeholder="Search accounts"
            />
          </div>
          {!!(customerDivisionsList?.length || Object.keys(customerDivisionsList)?.length) && (
            <div className={classes.menuGroupCategory}>
              <button
                type="button"
                className={isCloudProvider ? classes.selected : null}
                onClick={() => setIsCloudProvider(true)}
              >
                CLOUD PROVIDER
              </button>
              <button
                type="button"
                className={!isCloudProvider ? classes.selected : null}
                onClick={() => setIsCloudProvider(false)}
              >
                <span className={classes.iconWrap}>
                  CUSTOMER
                  <Tooltip title="Browse by customer to see associated accounts" arrow className="toolTip">
                    <span className={classes.infoIcon}>
                      <InfoIcon />
                    </span>
                  </Tooltip>
                </span>
              </button>
            </div>
          )}
          {isCloudProvider
            ? renderAccounts(filteredAccountsObj, currentCloudName)
            : renderCustomerAccount(filteredCustomerObj, currentCloudName, currDispUserDivisionId)}
          <UserAuthorizationDisplayWrapper
            authorizedUsersTypeList={mapUserActionsToAuthorizedUsers.get(USER_ACTIONS.ADD_ACCOUNT)}
            viewerUserType={currentDisplayedUserType}
          >
            <ReadOnlyDisplayWrapper isHide={false} userReadOnly={usersStore.currentUserReadOnly}>
              <div
                className={classes.item}
                role="button"
                id="add_account_navbar"
                onClick={() => {
                  toggle();
                  history.push(Routes.ACCOUNTS_OVERVIEW);
                }}
              >
                <PlusCircle size={16} />
                <span style={{ marginLeft: '10px' }}>Add account</span>
              </div>
            </ReadOnlyDisplayWrapper>
          </UserAuthorizationDisplayWrapper>
        </PopoverContent>
      </div>
    </Popover>
  );
};

AccountPicker.propTypes = {
  currentDisplayedAccount: PropTypes.string.isRequired,
  currentDisplayedCloudTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentDisplayedUserType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handleAccountChange: PropTypes.func.isRequired,
  handleChangeCustomer: PropTypes.func,
  accountsList: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

AccountPicker.defaultProps = {
  currentDisplayedCloudTypeId: null,
  handleChangeCustomer: null,
};
export default AccountPicker;
