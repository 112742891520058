import {
  AZURE_BILLING_HISTORY_TABLE_COLUMNS,
  AZURE_BILLING_HISTORY_TABLE_COLUMNS_WIDTHS,
  FLEXIBILITY_TABLE_COLUMNS_WIDTHS,
} from '../../../constants/customersConstants';
import { BILLING_HISTORY_TABLE_COLUMNS } from '../../CustomersHistory';

export function getTableColumnsAndWidths(isAzure, tableType?: string) {
  if (isAzure) {
    return {
      tableColumns: AZURE_BILLING_HISTORY_TABLE_COLUMNS,
      columnWidths: AZURE_BILLING_HISTORY_TABLE_COLUMNS_WIDTHS,
    };
  }
  return {
    tableColumns: BILLING_HISTORY_TABLE_COLUMNS,
    columnWidths: FLEXIBILITY_TABLE_COLUMNS_WIDTHS(tableType),
  };
}

export const LINES_IN_PAGE = 15;
