import React from 'react';
import classNames from 'classnames';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import Pagination from 'shared/components/andtComponents/Pagination';
import { MAX_EXPORTED_ROWS } from 'shared/constants/appConstants';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import commonStyles from 'app/containers/Onboarding/AwsOnboarding/pages/Pages.module.scss';
import SimpleSelect from 'shared/components/andtComponents/SimpleSelect';
import styles from './billingStatus.module.scss';
import classes from '../CustomersHistory/billingHistoryTableHeader.module.scss';

type Props = {
  rowsCount: number;
  onPageChange(newIndex: number): void;
  currentPage: number;
  totalPages: number;
  headerText?: string;
};
const Filters: React.FC<Props> = ({ headerText, currentPage, rowsCount, onPageChange, totalPages }) => (
  <div className={classes.header}>
    <div>
      <div className={classNames(styles.headerText, 'mb-3')}>
        <span>{headerText}</span>
        <span> ({rowsCount})</span>
      </div>
    </div>

    <div className={classes.rightMenu}>
      <Tooltip
        title={
          rowsCount > MAX_EXPORTED_ROWS
            ? `You can export up to ${MAX_EXPORTED_ROWS} records, please refine your filters to enable export`
            : 'Export'
        }
        arrow
      >
        <SimpleSelect
          className={commonStyles.select}
          value="default"
          options={[
            {
              label: (
                <span>
                  <GenerateIcon iconName={ICONS.download.name} /> Margin Summary
                </span>
              ),
              value: 'default',
            },
            {
              label: (
                <span>
                  <GenerateIcon iconName={ICONS.download.name} /> CSV
                </span>
              ),
              value: 'csv',
            },
            {
              label: (
                <span>
                  <GenerateIcon iconName={ICONS.download.name} /> PDF
                </span>
              ),
              value: 'pdf',
            },
          ]}
          onChange={() => 'TODO'}
        />
        <span />
      </Tooltip>
      <Pagination currPageIdx={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
    </div>
  </div>
);

export default Filters;
