import React, { useEffect, useState } from 'react';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import useTable from 'shared/hooks/customHooks/useTable';
import Spinner from 'shared/components/andtComponents/Spinner';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import { debounce } from 'lodash';
import { PAGINATION_DIRECTION, USER_ACTIONS } from '../../../consts';
import AddSubRolesModalTable from './AddSubRolesModalTable';
import AddSubRolesModalHeader from './AddSubRolesModalHeader';

import styles from './AddSubRolesModal.module.scss';

const AddSubRolesModal = ({ isOpen, setIsOpen, role }) => {
  const [saveClicked] = useState(false);
  const [selectedSubRoles, setSelectedSubRoles] = useState([]);
  const [paginationToken, setPaginationToken] = useState(null);
  const [displayedRoles, setDisplayedRoles] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchTextParam, setSearchTextParam] = useState(searchText);

  const { NewTableWrapper } = useTable();

  const onSearchTextChanged = debounce(setSearchTextParam, 1000);

  useEffect(() => {
    onSearchTextChanged(searchText);
  }, [searchText]);

  const { fetchRoles, addSubRoles } = useRoles(isOpen);
  const { data, isLoading } = fetchRoles({ paginationToken, search: searchTextParam });

  useEffect(() => {
    if (data?.roles && !isLoading) {
      const tmp = data.roles.filter((r) => !role.subRoles.find((subRole) => subRole.id === r.identifier.id));
      setDisplayedRoles(tmp);
    }
  }, [data, isLoading, role, isOpen]);

  const pageChanged = (direction) => {
    if (direction === PAGINATION_DIRECTION.NEXT && data?.paginationToken) {
      setPaginationToken(data?.paginationToken);
    }
  };

  const onSave = async () => {
    await addSubRoles.mutateAsync({
      roleInternalName: role?.identifier.internalName,
      subRolesInternalNames: selectedSubRoles?.map((r) => r.identifier.internalName),
    });
    setIsOpen(false);
  };

  return (
    <CustomModal
      className={styles}
      onClose={() => {
        setIsOpen(false);
      }}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      headerMode={MODAL_MODES.ADD}
      footerDisabled={isLoading && saveClicked}
      onSave={onSave}
      open={isOpen}
      automationId="assign-role-modal"
      saveTitle="Add"
      saveDisabled={false}
      title={USER_ACTIONS.ASSIGN_ROLES.label}
    >
      <>
        {isLoading ? (
          <Spinner />
        ) : (
          <NewTableWrapper className="sub-table" isCompact>
            <AddSubRolesModalHeader
              pageChanged={pageChanged}
              selectedRolesCount={selectedSubRoles?.length}
              searchText={searchText}
              setSearchText={setSearchText}
              total={displayedRoles?.length}
            />
            <AddSubRolesModalTable roles={displayedRoles} setSelectedUserRoles={setSelectedSubRoles} />
          </NewTableWrapper>
        )}
      </>
    </CustomModal>
  );
};

export default AddSubRolesModal;
