import React from 'react';
import Button from 'shared/components/andtComponents/Button';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

import styles from './RemoveButton.module.scss';

const RemoveButton = ({ showRemoveComponent, isMultiple, selectedCounter, removeClicked }) =>
  showRemoveComponent ? (
    <Button
      className={{ button: styles.removeRolesContainer, buttonContent: styles.content }}
      isTextButton
      icon={() => <GenerateIcon iconName={ICONS.circleMinus.name} className={styles.icon} />}
      onClick={removeClicked}
      text={isMultiple ? `Remove Selected (${selectedCounter})` : 'Remove'}
    />
  ) : (
    <div />
  );

RemoveButton.defaultProps = {
  showRemoveComponent: true,
  isMultiple: false,
  selectedCounter: 0,
};

export default RemoveButton;
