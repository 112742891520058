// add_aws_support.        -> type, disable_minimum_fee, plan
// add_cost_with_factor    -> type, factor, ustom_service
// add_fixed_cost.         -> type, amount, linked_account_id, custom_service
// remove_aws_support      -> type, disable_minimum_fee, plan
// set_fixed_rate          -> type, amount
// update_cost_with_factor -> type, factor
import { percentStr } from 'shared/utils/strUtil';
import { FilterTypes } from 'usage/constants/usageConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { getBillingRuleFiltersByCloudType } from 'invoices/constants/FiltersOptionsData';

export const columnExtensions = [
  {
    columnName: 'customerNames',
    width: 'auto',
  },
  {
    columnName: 'linkedAccountIds',
    width: 'auto',
  },
  {
    columnName: 'ruleName',
    width: 'auto',
  },
  {
    columnName: 'margin',
    width: 70,
  },
  {
    columnName: 'marginType',
    width: 205,
  },
  {
    columnName: 'frequency',
    width: 100,
  },
  {
    columnName: 'startMonth',
    width: 100,
  },
  {
    columnName: 'endMonth',
    width: 100,
  },
  {
    columnName: 'expired',
    width: 40,
  },
  {
    columnName: 'edit',
    width: 35,
    sortingEnabled: false,
  },
  {
    columnName: 'clone',
    width: 35,
    sortingEnabled: false,
  },
  {
    columnName: 'delete',
    width: 35,
    sortingEnabled: false,
  },
];

export const getMarginToDisplay = (margin) => {
  switch (margin.type) {
    case 'update_cost_with_factor':
    case 'add_cost_with_factor':
      // rounding after 10 decimal places to fix floating point precision issues
      return percentStr(margin.factor * 100, 10);
    case 'add_fixed_cost':
    case 'set_fixed_rate':
      return `$ ${margin.amount}`;
    case 'add_aws_support':
    case 'remove_aws_support':
      return margin.plan;
    default:
      return '';
  }
};

export const marginTypeToDisplayName = {
  update_cost_with_factor: 'Percentage',
  add_fixed_cost: 'Fixed Cost',
  set_fixed_rate: 'Fixed Rate',
  add_cost_with_factor: 'Custom Service Aggregated %',
  add_aws_support: 'AWS Support',
  remove_aws_support: 'Remove AWS Support',
};

export const MARGIN_TYPES_IDS = {
  UPDATE_COST_WITH_FACTOR: 'update_cost_with_factor',
  ADD_FIXED_COST: 'add_fixed_cost',
  SET_FIXED_RATE: 'set_fixed_rate',
  ADD_COST_WITH_FACTOR: 'add_cost_with_factor',
  ADD_AWS_SUPPORT: 'add_aws_support',
  REMOVE_AWS_SUPPORT: 'remove_aws_support',
  DATA_EXCLUSION: 'data_exclusion',
};

export const marginTypes = [
  {
    value: MARGIN_TYPES_IDS.UPDATE_COST_WITH_FACTOR,
    label: 'Percentage',
    description:
      // eslint-disable-next-line max-len
      'Percentage discount or charge on line items. This rule will affect the total of each line item and will not be displayed as a separate service.',
  },
  {
    value: MARGIN_TYPES_IDS.ADD_FIXED_COST,
    label: 'Fixed Cost',
    description: 'Set charge/discount amount. This rule will add a new service line to Anodot.',
  },
  {
    value: MARGIN_TYPES_IDS.SET_FIXED_RATE,
    label: 'Fixed Rate',
    description:
      // eslint-disable-next-line max-len
      'This rule will change the rate of a line item (ex: if Inter-AZ data transfer out is $0.01/gb, it can be changed to $0.005/gb). This rule will change the rate of every relevant resource.',
  },
  {
    value: MARGIN_TYPES_IDS.ADD_COST_WITH_FACTOR,
    label: 'Custom Service Aggregated %',
    description:
      // eslint-disable-next-line max-len
      'Define a charge/discount percentage based on the filters that you choose. This rule will add a new service line to Anodot.',
  },
  {
    value: MARGIN_TYPES_IDS.ADD_AWS_SUPPORT,
    label: 'AWS Support',
    description: 'Add a support charge according to the selected plan.',
  },
  {
    value: MARGIN_TYPES_IDS.REMOVE_AWS_SUPPORT,
    label: 'Remove AWS Support',
    description: 'Remove a support charge according to the selected plan.',
  },
  {
    value: MARGIN_TYPES_IDS.DATA_EXCLUSION,
    label: 'Data Exclusion',
    description:
      // eslint-disable-next-line max-len
      'Removes all records and data specified by the filters, ensuring that customers do not see any of the excluded information.',
  },
];

export const marginTypesByCloudType = {
  [CLOUD_TYPE_IDS.AWS]: marginTypes,
  [CLOUD_TYPE_IDS.AZURE]: marginTypes.filter(
    (marginType) => ![MARGIN_TYPES_IDS.ADD_AWS_SUPPORT, MARGIN_TYPES_IDS.REMOVE_AWS_SUPPORT].includes(marginType.value),
  ),
};

export const getFilterStatusTypeMapByCloudType = (cloudType, filters, defaultExcludeFields) => {
  let filterStatusTypeMapByCloudType = null;
  const getExcludeObject = (excludeValues, defaultExcludeValue) => {
    if (!excludeValues && defaultExcludeValue) {
      return FilterTypes.EXCLUDE;
    }
    const valueObject = {};
    let ExcludeValue = null;
    excludeValues?.forEach((obj) => {
      const parentObj = obj.split(':');
      if (parentObj.length === 1) {
        ExcludeValue = FilterTypes.EXCLUDE;
      }
      valueObject[parentObj[0]] = FilterTypes.EXCLUDE;
    });
    return ExcludeValue || valueObject;
  };

  const getFilterField = (fieldName) => [
    fieldName,
    (!filters?.excludeFilters || !filters.excludeFilters[fieldName]) && !defaultExcludeFields?.includes(fieldName)
      ? FilterTypes.INCLUDE
      : getExcludeObject(filters?.excludeFilters[fieldName], defaultExcludeFields?.includes(fieldName)),
  ];

  switch (cloudType) {
    case CLOUD_TYPE_IDS.AWS:
      filterStatusTypeMapByCloudType = new Map(
        getBillingRuleFiltersByCloudType(CLOUD_TYPE_IDS.AWS).map((item) => getFilterField(item)),
      );
      break;
    case CLOUD_TYPE_IDS.AZURE:
      filterStatusTypeMapByCloudType = new Map(
        getBillingRuleFiltersByCloudType(CLOUD_TYPE_IDS.AZURE).map((item) => getFilterField(item)),
      );
      break;
    default:
      filterStatusTypeMapByCloudType = new Map();
  }
  return filterStatusTypeMapByCloudType;
};
