import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import Spinner from 'shared/components/andtComponents/Spinner';
import { isRouteAllowed, Routes } from 'shared/constants/routes';
import { observer } from 'mobx-react';
import { usePermissionCategories } from 'usage/hooks/react-query/usePermissionCategories';

const AuthenticatedRoute = ({
  component: C,
  props: cProps,
  observeResources = [],
  blockResources = [],
  remountKey,
  ...rest
}) => {
  const { usersStore, appStore } = cProps;
  const { fetchAccessibleCategories } = usePermissionCategories();
  const { data: permissionCategories, isLoading } = fetchAccessibleCategories();

  useEffect(() => {}, [cProps.usersStore.currUserInitDone, ...observeResources.map((resFunc) => !!resFunc(cProps))]);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!cProps || !cProps.isSessionStorageAuth()) {
          return (
            <Redirect
              to={`${Routes.LOG_IN}?redirect=${props.location.pathname}${encodeURIComponent(props.location.search)}`}
            />
          );
        }
        if (
          !cProps.usersStore.currUserInitDone ||
          cProps.newTabLoading ||
          blockResources.some((resFunc) => !!resFunc(cProps)) ||
          isLoading
        ) {
          return <Spinner />;
        }
        if (
          !isLoading &&
          !isRouteAllowed(
            props.match.path,
            appStore.isKeyCloakManagement,
            permissionCategories,
            usersStore.currentDisplayedUserType,
          ) &&
          props.match.path !== Routes.NOT_ALLOWED
        ) {
          return <Redirect to={Routes.NOT_ALLOWED} />;
        }
        return (
          <C
            key={remountKey ? remountKey(cProps) : props.location.key}
            {...props}
            {...cProps}
            isPpApplied={cProps.appStore.isPpApplied}
          />
        );
      }}
    />
  );
};

export default observer(AuthenticatedRoute);
