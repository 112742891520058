import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { mapK8sCostTypesToDisplay } from 'shared/constants/k8sConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import ControlledToggleButton from 'shared/components/buttons/ControlledToggleButton';
import FilterContentListNew from 'shared/components/FilterSidebar/FilterContentList';
import { SearchFilters } from 'recommendationsNew/components/heatMap/heatMapFilters/additionalFilters/searchFilters';
import { useRootStore } from 'app/contexts/RootStoreContext';
import styles from './FieldSidebarFilter.module.scss';
import { getLabel } from './filterUtil';

const renderK8sCostType = (props) => {
  const { isForK8S, selectedK8SCostType, k8sCostTypeItems, handleK8SCostTypeChecked } = props;
  if (!isForK8S) {
    return null;
  }
  return (
    <div className="field-sidebar-filter">
      <div className="field-name-container mb-2">
        <p className="field-name">K8s Cost Types</p>
      </div>
      {k8sCostTypeItems.map((costType) => (
        <Col key={costType} className="ps-0">
          <div className="toggler-with-text-wrapper ml-0">
            <Col className="toggler-inner-item flex-grow-0">
              <ControlledToggleButton
                checked={selectedK8SCostType.includes(costType)}
                selectedItem={costType}
                handler={handleK8SCostTypeChecked}
              />
            </Col>
            <Col className="toggler-inner-item ms-3">{mapK8sCostTypesToDisplay.get(costType)}</Col>
          </div>
        </Col>
      ))}
    </div>
  );
};

renderK8sCostType.propTypes = {
  selectedK8SCostType: PropTypes.array,
  k8sCostTypeItems: PropTypes.array,
  handleK8SCostTypeChecked: PropTypes.func,
};
renderK8sCostType.defaultProps = {
  selectedK8SCostType: null,
  k8sCostTypeItems: [],
  handleK8SCostTypeChecked: null,
};

const FiltersSidebarContainer = ({ ...props }) => {
  const [filtersSearchString, setFiltersSearchString] = React.useState('');
  const { usersStore } = useRootStore();
  // eslint-disable-next-line react/prop-types
  const {
    fieldToFieldDistincValuesMap,
    filtersLoading,
    selectedOptionsMap,
    isForK8S,
    isForCue,
    className,
    showTitle,
    fieldsWidth,
    hideSearch,
  } = props;
  const fieldToFieldDistinctMapFiltered = React.useMemo(() => {
    if (!filtersSearchString) {
      return fieldToFieldDistincValuesMap;
    }
    const newMap = new Map();
    [...fieldToFieldDistincValuesMap.entries()].forEach(([key, value]) => {
      const fieldLabel = getLabel(key, usersStore.currDispUserCloudAccountType)?.toLowerCase();
      if (fieldLabel?.includes(filtersSearchString.toLowerCase())) {
        newMap.set(key, value);
      }
    });
    return newMap;
  }, [fieldToFieldDistincValuesMap, filtersSearchString]);
  return (
    <div
      className={`filters-sidebar-container ${className} ${isForCue ? 'cue' : ''} `}
      style={{ transform: 'scaleX(-1)' }}
    >
      <div style={{ transform: 'scaleX(-1)', marginLeft: 1 }}>
        {showTitle ? <h3 className="title">{`${'Filters'.toUpperCase()}:`}</h3> : null}
        {!hideSearch && (
          <div className={styles.filtersSearch}>
            <SearchFilters
              placeholder="Search Filter"
              searchValue={filtersSearchString}
              onSearchChange={setFiltersSearchString}
            />
          </div>
        )}
        <div className="filters-collapse-inner-wrapper">
          {renderK8sCostType(props)}
          <FilterContentListNew
            fieldToFieldDistincValuesMap={fieldToFieldDistinctMapFiltered}
            selectedOptionsMap={selectedOptionsMap}
            isForK8S={isForK8S}
            generalProps={props}
            fieldsWidth={fieldsWidth}
          />
        </div>
        {filtersLoading && (
          <div className={styles.loadingScreen}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

const ObserverFiltersSidebarContainer = withInvoiceFiltersContextConsumer(observer(FiltersSidebarContainer));
export default ObserverFiltersSidebarContainer;

FiltersSidebarContainer.propTypes = {
  props: PropTypes.object.isRequired,
  fieldToFieldDistincValuesMap: PropTypes.object.isRequired,
  filtersLoading: PropTypes.bool.isRequired,
  selectedOptionsMap: PropTypes.object.isRequired,
  isForK8S: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  fieldsWidth: PropTypes.number.isRequired,
  showTitle: PropTypes.bool.isRequired,
  isForCue: PropTypes.bool.isRequired,
};
