import toast from 'shared/components/andtComponents/Toast';

export const BILLINGS_API_NAME = 'billings';
export const USER_MANAGEMENT_ROOT = '/api/v1/user-management';
export const USER_MANAGEMENT_USERS = `${USER_MANAGEMENT_ROOT}/users`;
export const USER_MANAGEMENT_ROLES = `${USER_MANAGEMENT_ROOT}/roles`;

export const handleError = (result) => {
  if (result?.response?.status === 403) {
    toast.error('Users action failed: The authenticated user is not authorized');
  }
  if (result?.response?.status === 500) {
    toast.error('Users action failed: Server Error');
    throw result;
  }
};
