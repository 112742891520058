import { useMutation, useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { STALE_TIME } from 'users/containers/Organization/consts';
import {
  addRolesToUsers,
  createUsers,
  deleteUsers,
  downloadUsers,
  enableDisableUsers,
  fetchUsers,
  removeRolesFromUsers,
  resetUsersPassword,
  updateUser,
} from './apiUsers';
import { handleError } from './helperFunctions';

export default function useUsers(isEnabled) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const queryKey = [apiConstants.QUERY_KEYS.USER_MANAGEMENT_USERS, userAccountKey];

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    createUsers: useMutation(({ users }) => createUsers(users), {
      onSuccess: () => queryClient.invalidateQueries(apiConstants.QUERY_KEYS.USER_MANAGEMENT_USERS),
      onError: handleError,
    }),
    updateUser: useMutation(({ user }) => updateUser(user), {
      onSuccess: () => queryClient.invalidateQueries(apiConstants.QUERY_KEYS.USER_MANAGEMENT_USERS),
      onError: handleError,
    }),
    deleteUsers: useMutation(({ userIds }) => deleteUsers(userIds), {
      onSuccess: () => queryClient.invalidateQueries(apiConstants.QUERY_KEYS.USER_MANAGEMENT_USERS),
      onError: handleError,
    }),
    fetchUsers: (params) =>
      useQuery([...queryKey, ...(params ? Object.values(params) : [])], () => fetchUsers(params), {
        enabled: isEnabled === undefined ? true : isEnabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    addRolesToUsers: useMutation(({ roles, userIds }) => addRolesToUsers(roles, userIds), {
      onSuccess: () => queryClient.invalidateQueries(apiConstants.QUERY_KEYS.USER_MANAGEMENT_USERS),
      onError: handleError,
    }),
    removeRolesFromUsers: useMutation(({ roles, userIds }) => removeRolesFromUsers(roles, userIds), {
      onSuccess: () => queryClient.invalidateQueries(apiConstants.QUERY_KEYS.USER_MANAGEMENT_USERS),
      onError: handleError,
    }),
    enableDisableUsers: useMutation(({ userIds, enable }) => enableDisableUsers(userIds, enable), {
      onSuccess: () => queryClient.invalidateQueries(apiConstants.QUERY_KEYS.USER_MANAGEMENT_USERS),
      onError: handleError,
    }),
    resetUsersPassword: useMutation(({ userIds, enable }) => resetUsersPassword(userIds, enable), {
      onError: handleError,
    }),
    exportCsv: (selectedUsers) =>
      useQuery([...queryKey, ...selectedUsers], () => downloadUsers(selectedUsers), {
        retry: false,
        staleTime: STALE_TIME,
      }),
  };
}
