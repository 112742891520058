import React, { useCallback, useState } from 'react';
import SwitchButton from 'shared/components/andtComponents/Switch';
import Spinner from 'shared/components/andtComponents/Spinner';
import TableLikeHeader from 'shared/components/TableLikeHeader.tsx';
import RowLikeAccordion from 'shared/components/RowLikeAccordion';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { IconNames } from '@pileus-cloud/anodot-frontend-common/dist/types';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import classes from 'app/containers/Layout/topNavbar/TopNavBar.module.scss';
import { AlertLevelsEnum, AlertTypesEnum, ServiceCostAlert, ServiceCostAlertUpdate } from '../types.ts';
import t from '../texts.ts';
import styles from '../alerts.module.scss';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal.tsx';
import { useAlerts } from '../hooks/useAlerts.ts';
import FormServiceCost from './FormServiceCost.tsx';
import UpdateServiceCostModal from './UpdateServiceCostModal.tsx';
import EditDeleteButtons from '../components/EditDeleteButtons.tsx';
import { costServerPropName, useUsersSettings } from '../hooks/useUsersSettings.ts';
import { prepareFiltersForSaving } from '../helpers.ts';
import { defaultServiceCostAlert, isDefaultExpanded } from './constants.ts';
import EmptyAlerts from '../components/EmptyState';
import { linkedLabelByCloudType } from '../../../constants/usageConstants';

export interface ServiceCostTabProps {
  costChangesAlerts: ServiceCostAlert[];
  staticAlerts?: ServiceCostAlert[];
  refetchAlerts(): void;
}
const generateAlertName = (alert: ServiceCostAlert) =>
  `${t('COST_CHANGES')} (${alert.uuid}): Change exceeded ${alert.changeValue}`;

const getAlertLevelBadge = (cloudTypeId: keyof typeof CLOUD_TYPE_IDS, alertLevel?: AlertLevelsEnum) => {
  const isTotal = alertLevel === AlertLevelsEnum.TOTAL;
  const isLinked = alertLevel === AlertLevelsEnum.LINKED;

  let label = isTotal ? 'Total Cost' : 'Service-Level Cost';
  let iconName: IconNames = isTotal ? 'moneyBills' : 'cloud';
  if (isLinked) {
    label = `${linkedLabelByCloudType[cloudTypeId] || linkedLabelByCloudType[CLOUD_TYPE_IDS.AWS]} Level Cost`;
    iconName = 'link';
  }

  return (
    <div className={styles.alertsBadge}>
      <GenerateIcon iconName={ICONS[iconName]?.name} />
      <span>{label}</span>
    </div>
  );
};
const ServiceCostTab: React.FC<ServiceCostTabProps> = ({ costChangesAlerts, staticAlerts, refetchAlerts }) => {
  const [isAllExpanded, setIsAllExpanded] = useState(isDefaultExpanded);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCostChange, setShowCostChange] = useState(true);
  const [showStatic, setShowStatic] = useState(true);
  const [activeAlert, setActiveAlert] = useState<ServiceCostAlert | null>(null);
  const { updateAlerts, deleteAlert, setAlertsLocally } = useAlerts();
  const { mutate: handleUpdateAlert } = updateAlerts();
  const { refetch: handleDelete } = deleteAlert(activeAlert?.uuid || '');
  const { updateUsersSettings, fetchUsersSettings, setUsersSettingsLocally } = useUsersSettings();
  const { usersStore } = useRootStore();
  const cloudTypeId = usersStore.currDispUserCloudAccountType;
  const { refetch: refetchSettings, isLoading, data: usersSettings } = fetchUsersSettings();
  const { mutate: handleUpdateSettings } = updateUsersSettings({ onSuccess: () => refetchSettings() });
  const mergedAlerts = (showCostChange ? costChangesAlerts : []).concat((showStatic && staticAlerts) || []);
  const onCloseDeleteAlert = useCallback(() => setShowDeleteModal(false), []);
  const isActive = !!usersSettings?.[costServerPropName];
  const onUpdate = useCallback(
    (alert: ServiceCostAlert) => {
      const payload = [
        {
          alertStatus: 'save',
          alertType: String(alert.alertType) === String(AlertTypesEnum.STATIC) ? 'static' : 'changeCostService',
          alertLevel: alert.alertLevel,
          changeType: alert.changeType?.value,
          uuid: alert.uuid,
          changePercent: alert.changePercent,
          changeValue: alert.changeValue,
          creationDate: alert.creationDate,
          dayInWeek: alert.dayInWeek?.value || null,
          filters: prepareFiltersForSaving(alert.filters),
          granularity: alert.granularity.value,
          operatorType: alert.operatorType?.value,
          recipientsEmails: alert.recipientsEmails,
          recipients: alert.recipients,
          alertName: alert.alertName,
        },
      ] as ServiceCostAlertUpdate[];
      handleUpdateAlert(payload);
      setTimeout(refetchAlerts, 1000);
      setShowUpdateModal(false);
    },
    [handleUpdateAlert],
  );

  const onCreateClick = () => {
    setActiveAlert(null);
    setShowUpdateModal(true);
  };

  const onEditClick = (alert: ServiceCostAlert) => {
    setActiveAlert(alert);
    setShowUpdateModal(true);
  };

  const onDeleteClick = useCallback((alert: ServiceCostAlert) => {
    setActiveAlert(alert);
    setShowDeleteModal(true);
  }, []);

  const onDeleteAlert = useCallback(() => {
    handleDelete();
    setAlertsLocally(mergedAlerts.filter((a) => a.uuid !== activeAlert?.uuid));
    setTimeout(refetchAlerts, 700);
    setShowDeleteModal(false);
  }, [activeAlert?.uuid, mergedAlerts]);

  const onCloseUpdateModal = useCallback(() => {
    setShowUpdateModal(false);
    setActiveAlert(null);
  }, []);

  const onSwitchAlerts = useCallback((checked) => {
    handleUpdateSettings({ alertType: AlertTypesEnum.COST_CHANGES, checked });
    setUsersSettingsLocally(costServerPropName, checked ? 1 : 0);
  }, []);

  return (
    <div>
      <TableLikeHeader
        onExpandClick={() => {
          setIsAllExpanded((s) => !s);
        }}
        title={t('ALERT_RULES')}
        onCreate={onCreateClick}
        isExpanded={isAllExpanded}
        totalNumber={mergedAlerts.length}
        className={styles.marginBottom32}
      >
        <Tooltip title={t('ENABLE_ALL_SERVICE_COST')} arrow placement="top">
          {' '}
          <SwitchButton
            isChecked={isActive}
            onChange={onSwitchAlerts}
            className="styles.switch"
            style={{ marginLeft: 16 }}
          />
        </Tooltip>
        <div className={classes.divider} />
        <Checkbox
          automationId="pref-active-checkbox"
          className="me-3"
          isChecked={showCostChange}
          primary
          onChange={() => {
            setShowCostChange((s) => !s);
          }}
          text="Trend alerts"
        />
        <Checkbox
          automationId="pref-inactive-checkbox"
          isChecked={showStatic}
          primary
          onChange={() => {
            setShowStatic((s) => !s);
          }}
          text="Threshold alerts"
        />
      </TableLikeHeader>
      {isLoading && <Spinner />}
      {(!mergedAlerts.length || !isActive) && <EmptyAlerts canAdd onAdd={onCreateClick} />}
      {isActive &&
        mergedAlerts.map((alert) => (
          <RowLikeAccordion
            key={alert.uuid}
            isExternalExpand={isAllExpanded}
            headerContent={alert.alertName || generateAlertName(alert)}
            actionsBlock={
              <div className="d-flex justify-content-between" style={{ flexGrow: 1, maxWidth: 370, marginLeft: 10 }}>
                {getAlertLevelBadge(cloudTypeId, alert.alertLevel)}
                <EditDeleteButtons
                  alertId={alert.uuid}
                  onEdit={() => onEditClick(alert)}
                  onDelete={() => onDeleteClick(alert)}
                />
              </div>
            }
            automationId={`${alert.uuid}alert-container`}
          >
            <div className={styles.editInfo}>
              <GenerateIcon iconName={ICONS.infoStroke.name} /> <span>{t('EDIT_INFO')}</span>
            </div>
            <FormServiceCost key={alert.dbUpdateTime} alert={alert} disabled />
          </RowLikeAccordion>
        ))}
      {showUpdateModal && (
        <UpdateServiceCostModal
          isEdit={Boolean(activeAlert)}
          onUpdate={onUpdate}
          onClose={onCloseUpdateModal}
          alert={activeAlert || defaultServiceCostAlert}
        />
      )}
      {showDeleteModal && <DeleteConfirmationModal onClose={onCloseDeleteAlert} onDelete={onDeleteAlert} />}
    </div>
  );
};

export default ServiceCostTab;
